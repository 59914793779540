import { NIFS_API_BASE_URL, useQueryNifsCustomer } from '@ntb-sport/api';
import { NifsCustomerSettings } from '@ntb-sport/types';
import { Skeleton } from '@chakra-ui/react';

import { createContext, ReactNode } from 'react';

const defaultCustomer = {} as NifsCustomerSettings;

export const NifsCustomerContext = createContext({
  nifsCustomer: defaultCustomer,
});

export const NifsCustomerProvider = ({
  apiBaseUrl = NIFS_API_BASE_URL,
  sportGroup,
  site,
  active,
  children,
}: {
  apiBaseUrl: string;
  sportGroup: string;
  children: ReactNode;
  site: string;
  active: boolean;
}) => {
  const { data: customerSettings, isLoading } = useQueryNifsCustomer({
    queryKeyParams: { sportGroup, active, site },
    params: {
      apiBaseUrl,
      sportGroup,
      site,
      active,
    },
  });

  if (isLoading) {
    return <Skeleton w="100wv" h="100vh" />;
  }

  return (
    <NifsCustomerContext.Provider value={{ nifsCustomer: customerSettings }}>
      {children}
    </NifsCustomerContext.Provider>
  );
};

export default NifsCustomerProvider;
