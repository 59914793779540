import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import { COMPONENT_IDS, MATCH_TYPE_NAME } from '@ntb-sport/constants';

import { NifsMatch } from '@ntb-sport/types';

import { Skeleton } from '../../chakra';

import { TeamFixturesMatch } from '../TeamFixturesMatch/TeamFixturesMatch';

import { groupMatchesByDate, groupMatchesByRound } from './helpers';

import * as S from './TeamFixtures.styled';

interface TeamFixturesProps {
  data: any;
  isLoading?: any;
  ui?: any;
  showStadium?: boolean;
  isTeamMatches?: any;
  teamId?: any;
  onClickTeam?: any;
  onClickMatch?: any;
  tournamentId?: number;
  groupMatchesBy?: string | null;
  sortDirection?: 'ascending' | 'descending';
  loaderRowCount?: number;
  highlightWinningTeam?: boolean;
  displayStageName?: boolean;
  showRound?: boolean;
}

export const TeamFixtures = ({
  data,
  isLoading,
  isTeamMatches,
  teamId,
  onClickTeam,
  onClickMatch,
  showStadium,
  tournamentId,
  groupMatchesBy = 'date',
  sortDirection = 'descending',
  loaderRowCount = 10,
  highlightWinningTeam,
  displayStageName,
  showRound,
}: TeamFixturesProps) => {
  if (isLoading) {
    return (
      <S.Section data-component-id={COMPONENT_IDS.TEAM_FIXTURES}>
        <Skeleton width="100%" height="100%" minH="40px" mb={1} />
        {[...Array(loaderRowCount)].map((_item, index) => (
          <Skeleton
            key={index}
            width="100%"
            height="100%"
            minH="115px"
            mb={1}
          />
        ))}
      </S.Section>
    );
  }

  if (!data && !isLoading) return null;

  const groupedMatches =
    groupMatchesBy === 'round'
      ? groupMatchesByRound({ matches: data, sortDirection })
      : groupMatchesByDate({ matches: data, sortDirection });

  if (!data?.length) {
    return <S.NoResult>Ingen kamper tilgjengelig</S.NoResult>;
  }

  return (
    <div
      data-component-id={COMPONENT_IDS.TEAM_FIXTURES}
      data-tournament-id={tournamentId}
    >
      <S.ContainerQuery>
        {Object.values(groupedMatches).map((group: any) => (
          <S.Section key={group.key}>
            {groupMatchesBy && (
              <S.SectionTitle>
                {isLoading ? (
                  <Skeleton width="200px" height="20px" />
                ) : (
                  <>
                    {groupMatchesBy === 'round' &&
                      MATCH_TYPE_NAME[
                        group.matchTypeId as keyof typeof MATCH_TYPE_NAME
                      ]}
                    {groupMatchesBy === 'date' &&
                      format(new Date(group.date), 'EEEE d. MMMM yyyy', {
                        locale: nb,
                      })}
                  </>
                )}
              </S.SectionTitle>
            )}
            <S.Matches>
              {group?.matches.map((match: NifsMatch) => {
                return (
                  <TeamFixturesMatch
                    key={match.id}
                    matchRelation={match?.matchRelation}
                    sportId={match?.sportId}
                    isTeamMatches={isTeamMatches}
                    groupBy={groupMatchesBy}
                    stage={match?.stage}
                    homeTeam={match?.homeTeam}
                    awayTeam={match?.awayTeam}
                    timestamp={match?.timestamp}
                    result={match?.result}
                    matchStatusId={match?.matchStatusId}
                    displayStageName={displayStageName}
                    highlightWinningTeam={highlightWinningTeam}
                    onClickMatch={onClickMatch}
                    onClickTeam={onClickTeam}
                    teamId={teamId}
                    id={match?.id}
                    isLoading={isLoading}
                    showStadium={showStadium}
                    stadium={match?.stadium}
                    round={showRound && match?.round}
                  />
                );
              })}
            </S.Matches>
          </S.Section>
        ))}
      </S.ContainerQuery>
    </div>
  );
};

export default TeamFixtures;
