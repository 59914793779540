export const NIFS_STATISTICS_ACCESSOR_TO_NAME_MAP = {
  assists: 'Målgivende',
  assistsHome: 'Målgivende hjemme',
  assistsAway: 'Målgivende borte',
  assistsAverage: 'Målgivende snitt',
  matches: 'Antall kamper',
  goals: 'Mål',
  goalsHome: 'Mål hjemme',
  goalsAway: 'Mål borte',
  goalsAverage: 'Mål snitt',
  redCards: 'Røde kort',
  yellowCards: 'Gule kort',
};

export const ENGLISH_STATISTICS_TYPE_TO_NORWEGIAN_LABEL = {
  goals: 'Mål',
  cards: 'Kort',
  assists: 'Målgivende',
};
