import { useMemo } from 'react';

import { MATCH_STATUS } from '@ntb-sport/constants';

import {
  isAllowedCustomerOnLiveFeed,
  sortByAscending,
} from '@ntb-sport/helpers';

import { NifsLiveFeed } from '@ntb-sport/types';

import { matchIncludesSearchQuery } from '@ntb-sport/ui';

import { Match } from './Match/Match';

import * as S from './Matches.styled';

interface MatchesProps {
  matches: any;
  debouncedSearchQuery: string;
  onMatchClick: any;
  onClickSubscribeMatch: any;
  subscribedMatches?: number[];
  customerConcernId: any;
  onClickStandings: any;
  filterSubscribedMatches?: boolean;
}

export const Matches = ({
  matches,
  debouncedSearchQuery,
  onMatchClick,
  onClickSubscribeMatch,
  subscribedMatches,
  customerConcernId,
  onClickStandings,
  filterSubscribedMatches,
}: MatchesProps) => {
  const cachedMatchIncludesSearchQuery = useMemo(
    () => matchIncludesSearchQuery,
    [debouncedSearchQuery],
  );

  if (filterSubscribedMatches) {
    return (
      <S.Matches>
        {Object.values(matches)
          ?.filter(
            (match: any) =>
              filterSubscribedMatches &&
              subscribedMatches?.some(
                (subscribedMatch: any) => subscribedMatch.id === match.id,
              ),
          )
          .map((match: any) => (
            <Match
              key={match?.id}
              match={match}
              onMatchClick={onMatchClick}
              onClickSubscribeMatch={onClickSubscribeMatch}
              subscribedMatches={subscribedMatches}
              customerConcernId={customerConcernId}
              onClickStandings={onClickStandings}
              searchQuery={debouncedSearchQuery}
            />
          ))}
      </S.Matches>
    );
  }

  return (
    <S.Matches>
      {Object.values(matches)
        .filter(
          (match: any) =>
            match?.liveFeeds &&
            match?.matchStatusId !== MATCH_STATUS.POSTPONED &&
            cachedMatchIncludesSearchQuery(match, debouncedSearchQuery) &&
            isAllowedCustomerOnLiveFeed(
              match?.liveFeeds?.[0] as NifsLiveFeed,
              customerConcernId,
            ),
        )
        .sort((a: any, b: any) => {
          if (a.timestamp === b.timestamp) {
            return sortByAscending(a.name, b.name);
          } else {
            return sortByAscending(a.timestamp, b.timestamp);
          }
        })
        .map((match: any) => (
          <Match
            key={match?.id}
            match={match}
            onMatchClick={onMatchClick}
            onClickSubscribeMatch={onClickSubscribeMatch}
            subscribedMatches={subscribedMatches}
            customerConcernId={customerConcernId}
            onClickStandings={onClickStandings}
            searchQuery={debouncedSearchQuery}
          />
        ))}

      {Object.values(matches)
        .filter(
          (match: any) =>
            (!match?.liveFeeds &&
              match?.matchStatusId !== MATCH_STATUS.POSTPONED &&
              cachedMatchIncludesSearchQuery(match, debouncedSearchQuery)) ||
            !isAllowedCustomerOnLiveFeed(
              match?.liveFeeds?.[0] as NifsLiveFeed,
              customerConcernId,
            ),
        )
        .sort((a: any, b: any) => {
          if (a.timestamp === b.timestamp) {
            return sortByAscending(a.name, b.name);
          } else {
            return sortByAscending(a.timestamp, b.timestamp);
          }
        })
        .map((match: any) => (
          <Match
            key={match?.id}
            match={match}
            onMatchClick={onMatchClick}
            onClickSubscribeMatch={onClickSubscribeMatch}
            subscribedMatches={subscribedMatches}
            customerConcernId={customerConcernId}
            onClickStandings={onClickStandings}
            searchQuery={debouncedSearchQuery}
          />
        ))}

      {Object.values(matches)
        .filter(
          (match: any) =>
            match?.matchStatusId === MATCH_STATUS.POSTPONED &&
            cachedMatchIncludesSearchQuery(match, debouncedSearchQuery),
        )
        .sort((a: any, b: any) => {
          if (a.timestamp === b.timestamp) {
            return sortByAscending(a.name, b.name);
          } else {
            return sortByAscending(a.timestamp, b.timestamp);
          }
        })
        .map((match: any) => (
          <Match
            key={match?.id}
            match={match}
            onMatchClick={onMatchClick}
            onClickSubscribeMatch={onClickSubscribeMatch}
            subscribedMatches={subscribedMatches}
            customerConcernId={customerConcernId}
            onClickStandings={onClickStandings}
            searchQuery={debouncedSearchQuery}
          />
        ))}
    </S.Matches>
  );
};
