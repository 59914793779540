import { VISIBLE_REFEREE_GROUPS } from '@ntb-sport/constants';

import { NifsReferee } from '@ntb-sport/types';

export interface RefereeGroup {
  name: string;
  referees: NifsReferee[];
}

interface RefereesByTypeMap {
  [key: number]: RefereeGroup;
}

export function groupRefereesByType(
  referees: NifsReferee[],
): RefereesByTypeMap {
  const refereesByType = referees?.reduce(
    (acc: RefereesByTypeMap, referee: NifsReferee) => {
      if (acc[referee.refereeTypeId]) {
        acc[referee.refereeTypeId].referees.push(referee);
      }

      return acc;
    },
    VISIBLE_REFEREE_GROUPS,
  );

  return refereesByType;
}
