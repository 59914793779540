import { UUIDs } from 'neo-common-enums';

export const DISCIPLINE_NAME = {
  [UUIDs.Discipline.DECATHLON_INDIVIDUAL_100_METRES]: '100 m',
  [UUIDs.Discipline.DECATHLON_DISCUS_THROW]: 'Diskos',
  [UUIDs.Discipline.DECATHLON_HIGH_JUMP]: 'Høyde',
  [UUIDs.Discipline.DECATHLON_INDIVIDUAL_110_METRES_HURDLES]: '110 m hekk',
  [UUIDs.Discipline.DECATHLON_INDIVIDUAL_1500_METRES]: '1500 m',
  [UUIDs.Discipline.DECATHLON_INDIVIDUAL_400_METRES]: '400 m',
  [UUIDs.Discipline.DECATHLON_JAVELIN_THROW]: 'Spyd',
  [UUIDs.Discipline.DECATHLON_LONG_JUMP]: 'Lengde',
  [UUIDs.Discipline.DECATHLON_POLE_VAULT]: 'Stavsprang',
  [UUIDs.Discipline.DECATHLON_SHOT_PUT]: 'Kulestøt',
  [UUIDs.Discipline.PENTATHLON_HIGH_JUMP]: 'Høyde',
  [UUIDs.Discipline.PENTATHLON_INDIVIDUAL_60_METRES_HURDLES]: '60 m hekk',
  [UUIDs.Discipline.PENTATHLON_INDIVIDUAL_800_METRES]: '800 m',
  [UUIDs.Discipline.PENTATHLON_LONG_JUMP]: 'Lengde',
  [UUIDs.Discipline.PENTATHLON_SHOT_PUT]: 'Kulestøt',
  [UUIDs.Discipline.HEPTATHLON_HIGH_JUMP]: 'Høyde',
  [UUIDs.Discipline.HEPTATHLON_INDIVIDUAL_1000_METRES]: '1000 m',
  [UUIDs.Discipline.HEPTATHLON_INDIVIDUAL_100_METRES_HURDLE]: '100 m hekk',
  [UUIDs.Discipline.HEPTATHLON_INDIVIDUAL_200_METRES]: '200 m',
  [UUIDs.Discipline.HEPTATHLON_INDIVIDUAL_60_METRES]: '60 m',
  [UUIDs.Discipline.HEPTATHLON_INDIVIDUAL_60_METRES_HURDLE]: '60 m hekk',
  [UUIDs.Discipline.HEPTATHLON_JAVELIN_THROW]: 'Spyd',
  [UUIDs.Discipline.HEPTATHLON_LONG_JUMP]: 'Lengde',
  [UUIDs.Discipline.HEPTATHLON_POLE_VAULT]: 'Stavsprang',
  [UUIDs.Discipline.HEPTATHLON_SHOT_PUT]: 'Kulestøt',
};
