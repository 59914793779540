import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import * as S from './Stadium.styled';
import { NifsTeamCompact } from '@ntb-sport/types';

export const Stadium = ({ stadium }: { stadium: any }) => {
  return (
    <>
      <S.HighlightedStats>
        <S.HighlightedStat>
          <S.HighlightedStatHeader>Kapasitet</S.HighlightedStatHeader>
          <S.HighlightedStatBody>
            {stadium?.capacity || 'N/A'}
          </S.HighlightedStatBody>
        </S.HighlightedStat>
        <S.HighlightedStat>
          <S.HighlightedStatHeader>Sitteplasser</S.HighlightedStatHeader>
          <S.HighlightedStatBody>
            {stadium?.sittingCapacity || 'N/A'}
          </S.HighlightedStatBody>
        </S.HighlightedStat>
        <S.HighlightedStat>
          <S.HighlightedStatHeader>Tilskuerrekord</S.HighlightedStatHeader>
          <S.HighlightedStatBody>
            {stadium?.attendanceRecord || 'N/A'}
          </S.HighlightedStatBody>
        </S.HighlightedStat>
        <S.HighlightedStat>
          <S.HighlightedStatHeader>Åpnet</S.HighlightedStatHeader>
          <S.HighlightedStatBody>
            {stadium?.openedDate?.substr(0, 4) || 'N/A'}
          </S.HighlightedStatBody>
        </S.HighlightedStat>
      </S.HighlightedStats>
      {stadium?.image?.url && <S.Image src={stadium?.image?.url} />}
      <S.Stats>
        <S.Stat>
          <S.StatLabel>Hjemmelag</S.StatLabel>
          <S.StatContent>
            {stadium?.teams
              ?.filter((team: NifsTeamCompact) => !team?.dateEnd)
              ?.map((team: NifsTeamCompact) => team?.name)
              ?.join(', ')}
          </S.StatContent>
        </S.Stat>
        <S.Stat>
          <S.StatLabel>By/Sted</S.StatLabel>
          <S.StatContent>
            {stadium?.place?.name}
            {', '}
            {stadium?.country?.name}
          </S.StatContent>
        </S.Stat>
        {stadium?.pitchLength && stadium?.pitchWidth && (
          <S.Stat>
            <S.StatLabel>Banestørrelse</S.StatLabel>
            <S.StatContent>{`${stadium?.pitchLength}m x ${stadium?.pitchWidth}m`}</S.StatContent>
          </S.Stat>
        )}

        <S.Stat>
          <S.StatLabel>Underlag</S.StatLabel>
          <S.StatContent>{stadium?.surface}</S.StatContent>
        </S.Stat>

        {stadium?.hasFloodlights !== null && (
          <S.Stat>
            <S.StatLabel>Flomlys</S.StatLabel>
            <S.StatContent>
              {stadium?.hasFloodlights ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}

        {stadium?.hasUnderSoilHeating !== null && (
          <S.Stat>
            <S.StatLabel>Undervarme</S.StatLabel>
            <S.StatContent>
              {stadium?.hasUnderSoilHeating ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}

        {stadium?.hasRunningTrack !== null && (
          <S.Stat>
            <S.StatLabel>Løpebane</S.StatLabel>
            <S.StatContent>
              {stadium?.hasRunningTrack ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}

        {stadium?.roof !== null && (
          <S.Stat>
            <S.StatLabel>Uttrekkbart tak</S.StatLabel>
            <S.StatContent>
              {stadium?.roof ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}

        {stadium?.isIndoorArena !== null && (
          <S.Stat>
            <S.StatLabel>Innendørsbane</S.StatLabel>
            <S.StatContent>
              {stadium?.isIndoorArena ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}

        {stadium?.hasRetractablePitch !== null && (
          <S.Stat>
            <S.StatLabel>Uttrekkbart underlag</S.StatLabel>
            <S.StatContent>
              {stadium?.hasRetractablePitch ? (
                <CheckIcon
                  aria-label="Ja"
                  color="var(--base-colors-success)"
                  boxSize={3}
                />
              ) : (
                <CloseIcon
                  aria-label="Nei"
                  color="var(--base-colors-error)"
                  boxSize={3}
                />
              )}
            </S.StatContent>
          </S.Stat>
        )}
      </S.Stats>
    </>
  );
};
