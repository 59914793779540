export const TOURNAMENT_TYPE_ID = {
  NATIONAL_TEAM_TOURNAMENT: 1,
  CLUB_TOURNAMENT: 2,
  INTERNATIONAL_CLUB_TOURNAMENT: 3,
  CLUB_FRIENDLIES: 4,
  NATIONAL_TEAM_FRIENDLIES: 5,
  OPEN: 6, // Treningskamper
  YOUTH: 7, // NM Junior etc
};

export const TOURNAMENT_CATEGORY_ID = {
  NATIONAL_LEAGUE: 1,
  INTERNATIONAL_CUP: 2,
  NATIONAL_CUP: 3,
  NATIONAL_LEAGUE_CUP: 4,
  SUPER_CUP: 5,
  LOWER_LEAGUE_CUP: 6,
  REGIONAL_LEAGUE: 7,
  REGIONAL_CUP_QUALIFICATION: 8,
  NATIONAL_CUP_QUALIFICATION: 9,
  NATIONAL_LEAGUE_CUP_QUALIFICATION: 10,
  LOWER_LEAGUE_CUP_QUALIFICATION: 11,
  REGIONAL_CUP_QUALIFICATION_2: 12,
  INTERNATIONAL_CUP_QUALIFICATION: 13,
  INTERNATIONAL_TOURNAMENT: 30,
  INTERNATIONAL_REGIONAL_TOURNAMENT: 31,
  INTERNATIONAL_TOURNAMENT_QUALIFICATION: 32,
  INTERNATIONAL_REGIONAL_TOURNAMENT_QUALIFICATION: 33,
  FRIENDLIES: 99,
  OTHER: 100,
};

export const DOMESTIC_TOURNAMENT_CATEGORIES = [
  TOURNAMENT_CATEGORY_ID.NATIONAL_CUP,
  TOURNAMENT_CATEGORY_ID.NATIONAL_LEAGUE_CUP,
  TOURNAMENT_CATEGORY_ID.SUPER_CUP,
  TOURNAMENT_CATEGORY_ID.LOWER_LEAGUE_CUP,
  TOURNAMENT_CATEGORY_ID.REGIONAL_LEAGUE,
  TOURNAMENT_CATEGORY_ID.REGIONAL_CUP_QUALIFICATION,
  TOURNAMENT_CATEGORY_ID.NATIONAL_CUP_QUALIFICATION,
  TOURNAMENT_CATEGORY_ID.NATIONAL_LEAGUE_CUP_QUALIFICATION,
  TOURNAMENT_CATEGORY_ID.LOWER_LEAGUE_CUP_QUALIFICATION,
  TOURNAMENT_CATEGORY_ID.REGIONAL_CUP_QUALIFICATION_2,
];

export const TOURNAMENT_CATEGORY_VALUES = {
  DOMESTIC_LEAGUES: 'domesticLeagues',
  DOMESTIC_CUPS: 'domesticCups',
  INTERNATIONAL_CLUB_TOURNAMENT: 'internationalClubTournament',
  INTERNATIONAL_NATIONAL_TOURNAMENT: 'internationalNationalTournament',
};

export const TOURNAMENT_CATEGORY_LABELS = {
  DOMESTIC_LEAGUES: 'Nasjonale ligaer',
  DOMESTIC_CUPS: 'Nasjonale cuper',
  INTERNATIONAL_CLUB_TOURNAMENT: 'Internasjonale turneringer',
  INTERNATIONAL_NATIONAL_TOURNAMENT: 'Landslag',
};
