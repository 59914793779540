import {
  Row,
  Cell,
  FlexWrapper,
  HeaderCell,
  SortArrowDown,
  SortArrowUp,
  SortArrows,
  StyledTable,
} from './Table.styled';
import { useSortBy, useTable } from 'react-table';

import { MatchesType, NifsFairPlayTableClub } from '@ntb-sport/types';
import { Skeleton } from '../../chakra';

interface TableProps {
  columns: any;
  data: NifsFairPlayTableClub[];
  isLoading: boolean;
  matches?: MatchesType;
}

const Table: React.FC<TableProps> = ({ columns, data, isLoading, matches }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.reduce((hiddenColumns: any, column: any) => {
            if (!column.isVisible) {
              hiddenColumns.push(column.accessor);
            }
            return hiddenColumns;
          }, []),
        },
      },
      useSortBy,
    );

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...headerGroupProps}>
              {headerGroup.headers.map((column: any) => {
                const { key, ...headerProps } = column.getHeaderProps(
                  column.getSortByToggleProps(),
                );
                return (
                  <HeaderCell
                    key={key}
                    $matches={matches}
                    $isSorted={column.isSorted}
                    style={column.styles}
                    {...headerProps}
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <FlexWrapper $matches={matches} style={column.styles}>
                        {column.render('Header')}
                        {column.isSortable && (
                          <SortArrows>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortArrowUp />
                              ) : (
                                <SortArrowDown />
                              )
                            ) : (
                              <>
                                <SortArrowUp />
                                <SortArrowDown />
                              </>
                            )}
                          </SortArrows>
                        )}
                      </FlexWrapper>
                    )}
                  </HeaderCell>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          const { key, rowProps } = row.getRowProps();
          return (
            <Row key={key} {...rowProps}>
              {row.cells.map((cell: any) => {
                const { key, ...cellProps } = cell.getCellProps();
                return (
                  <Cell
                    key={key}
                    $matches={matches}
                    $isSorted={cell.column.isSorted}
                    style={cell.column.styles}
                    {...cellProps}
                  >
                    {isLoading ? <Skeleton /> : cell.render('Cell')}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
