import { SporteditActiveAthleteParticipant } from '@ntb-sport/types';
import { ActiveAthleteParticipant } from './ActiveAthleteParticipant/ActiveAthleteParticipant';
import './active-athlete.css';

import { useBem } from '@ntb-sport/hooks';

interface NextEventParticipantsProps {
  nextEventParticipants: SporteditActiveAthleteParticipant[];
}

export const NextActiveAthletes = ({
  nextEventParticipants,
}: NextEventParticipantsProps) => {
  const bem = useBem('next-active-athletes');

  return (
    <div className={bem()}>
      {nextEventParticipants.map(
        (
          eventParticipant: SporteditActiveAthleteParticipant,
          index: number,
        ) => {
          const participantTime =
            eventParticipant?.startTimeDifferenceToCurrentLeader;
          const notZero =
            Number(participantTime) !== 0 || participantTime !== null
              ? 'not-zero'
              : '';

          return (
            <div className={bem('participant')} key={eventParticipant.uuid}>
              <div className={bem('details')}>
                <span className={bem('start-number')}>{index + 1}.</span>
                <ActiveAthleteParticipant eventParticipant={eventParticipant} />
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
