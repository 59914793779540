import * as S from './Match.styled';

import {
  COMPONENT_IDS,
  CUSTOMER_CONCERN_IDS,
  ELEMENT_IDS,
  MATCH_EVENTS,
  MATCH_STATUS_ID,
  MATCH_TYPE_NAME,
  SPORT_ID,
  STAGE_TYPE,
} from '@ntb-sport/constants';

import MatchStatus from './MatchStatus/MatchStatus';
import {
  checkIfAllowedToShowLiveReport,
  isMatchInProgress,
  isMatchNotStarted,
} from '@ntb-sport/helpers';
import { Fragment, useEffect, useContext } from 'react';
import { useStateWithDep } from '@ntb-sport/hooks';
import { NifsCustomerContext } from '@ntb-sport/customer';
import { NifsCustomerSettings } from '@ntb-sport/types';

import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { TbDots } from 'react-icons/tb';
import { StandingsIcon2 } from '../../../../icons';
import ClubLogo from '../../../ClubLogo/ClubLogo';
import {
  IconButton,
  Highlight,
  MenuChakra as Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '../../../../chakra';

interface MatchProps {
  match: any;
  onMatchClick: any;
  onClickSubscribeMatch?: any;
  subscribedMatches?: number[];
  customerConcernId: any;
  onClickStandings: any;
  searchQuery: string;
}

function isMatchInInnings(matchStatusId: number) {
  return (
    matchStatusId === MATCH_STATUS_ID.FIRST_HALF_EXTRA_TIME ||
    matchStatusId === MATCH_STATUS_ID.HALF_TIME_EXTRA_TIME ||
    matchStatusId === MATCH_STATUS_ID.SECOND_HALF_EXTRA_TIME
  );
}

function isMatchAfterInnings({
  matchStatusId,
  result,
}: {
  matchStatusId: number;
  result: any;
}) {
  return (
    (matchStatusId === MATCH_STATUS_ID.PLAYED ||
      matchStatusId === MATCH_STATUS_ID.PENALTY_SHOOTOUT) &&
    result &&
    Object.prototype.hasOwnProperty.call(result, 'homeScore120')
  );
}

export const Match = ({
  match,
  onMatchClick,
  onClickSubscribeMatch,
  onClickStandings,
  subscribedMatches,
  customerConcernId,
  searchQuery,
}: MatchProps) => {
  const regExp = /U\d\d/;
  const homeTeamMatches = regExp.exec(match.homeTeam.name);
  const awayTeamMatches = regExp.exec(match.awayTeam.name);
  const homeTeamCountry = match.homeTeam.name
    .substr(0, homeTeamMatches?.index)
    .trim();
  const awayTeamCountry = match.awayTeam.name
    .substr(0, awayTeamMatches?.index)
    .trim();
  const [highlightEvent, setHighlightEvent] = useStateWithDep(match?.eventData);

  const { nifsCustomer } = useContext(NifsCustomerContext) as {
    nifsCustomer: NifsCustomerSettings;
  };

  const isLeague =
    match.stage.stageTypeId === STAGE_TYPE.LEAGUE ||
    match.stage?.stageTypeId === STAGE_TYPE.LEAGUE_WITH_PENALTIES;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (
      !highlightEvent?.isUpdate &&
      (highlightEvent?.matchEventTypeId === MATCH_EVENTS.GOAL ||
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.DISALLOWED_GOAL ||
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.RED_CARD ||
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.PENALTY ||
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS)
    ) {
      timer = setTimeout(() => {
        setHighlightEvent(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [highlightEvent, setHighlightEvent]);

  const eventTeam = match?.eventData?.team?.name;
  const eventPerson =
    match?.eventData?.person && match?.eventData?.person?.name;

  // Amedia
  const matchStreams =
    (customerConcernId === CUSTOMER_CONCERN_IDS.AMEDIA ||
      customerConcernId === CUSTOMER_CONCERN_IDS.NETTAVISEN) &&
    match?.matchStreams?.filter(
      (stream: any) =>
        stream?.customerConcernId === CUSTOMER_CONCERN_IDS.AMEDIA ||
        stream?.customerConcernId === CUSTOMER_CONCERN_IDS.NETTAVISEN,
    );
  const matchStatus = isMatchInProgress(match?.matchStatusId)
    ? 'in_progress'
    : MATCH_STATUS_ID[match?.matchStatusId]?.toLocaleLowerCase();

  const showComments =
    (nifsCustomer?.includeStageIds?.includes(match?.stage?.id) ||
      nifsCustomer?.includeStageIds === null) &&
    !nifsCustomer?.excludeStageIds?.includes(match?.stage?.id);

  const isAllowedToShowLiveReport = checkIfAllowedToShowLiveReport(
    match?.liveFeeds?.[0],
    nifsCustomer?.customer?.id,
  );

  const onFocus = () => {
    document.getElementById(COMPONENT_IDS.TEAM_MATCH_RESULT)?.focus();
  };

  return (
    <S.Wrapper
      data-element-id={ELEMENT_IDS.MATCH}
      $isGoal={
        !highlightEvent?.isUpdate &&
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.GOAL
      }
      $isDissalowedGoal={
        !highlightEvent?.isUpdate &&
        highlightEvent?.matchEventTypeId === MATCH_EVENTS.DISALLOWED_GOAL
      }
    >
      <S.Match
        as="h3"
        aria-label={`${match?.homeTeam?.name} mot ${match?.awayTeam?.name}. Gå til kamp-referat ved å klikke en av kampens informasjonsdetaljer.`}
        key={match.id}
        $isClickable={Boolean(onMatchClick)}
        $isGoal={
          !highlightEvent?.isUpdate &&
          highlightEvent?.matchEventTypeId === MATCH_EVENTS.GOAL
        }
        $isDissalowedGoal={
          !highlightEvent?.isUpdate &&
          highlightEvent?.matchEventTypeId === MATCH_EVENTS.DISALLOWED_GOAL
        }
        onClick={() => {
          Boolean(onMatchClick) && onMatchClick({ matchId: match?.id });
          onFocus();
        }}
      >
        <S.MatchStatus data-status-id={matchStatus}>
          <MatchStatus
            sportId={match?.sportId}
            matchStatusId={match.matchStatusId}
            timestamp={match.timestamp}
            timeStartFirstHalf={match.timeStartFirstHalf}
            timeStartSecondHalf={match.timeStartSecondHalf}
            timeStartFirstHalfExtraTime={match.timeStartFirstHalfExtraTime}
            timeStartSecondHalfExtraTime={match.timeStartSecondHalfExtraTime}
          />
        </S.MatchStatus>

        <S.MatchScore
          data-status-id={matchStatus}
          data-element-id={ELEMENT_IDS.SCORE}
        >
          {isMatchNotStarted(match.matchStatusId) ? (
            '-'
          ) : (
            <>
              <S.MatchScoreHomeTeam>
                {match?.sportId === 2
                  ? match?.result?.homeScorePenalties ||
                    match?.result?.homeScore120 ||
                    match?.result?.homeScore90
                  : match?.result?.homeScore120 || match?.result?.homeScore90}
              </S.MatchScoreHomeTeam>
              <S.MatchScoreAwayTeam>
                {match?.sportId === 2
                  ? match?.result?.awayScorePenalties ||
                    match?.result?.awayScore120 ||
                    match?.result?.awayScore90
                  : match?.result?.awayScore120 || match?.result?.awayScore90}
              </S.MatchScoreAwayTeam>
            </>
          )}
        </S.MatchScore>

        {(match?.sportId === SPORT_ID.FOOTBALL ||
          match?.sportId === SPORT_ID.HANDBALL) && (
          <>
            {isMatchInInnings(match.matchStatusId) ? (
              <S.Innings>eo</S.Innings>
            ) : (
              isMatchAfterInnings({
                matchStatusId: match.matchStatusId,
                result: match.result,
              }) && <S.Innings>eeo</S.Innings>
            )}
          </>
        )}

        <S.AdditionalScores>
          {highlightEvent?.matchEventTypeId ===
            MATCH_EVENTS.DISALLOWED_GOAL && (
            <S.AdditionalScore>{`Annulert mål: ${eventTeam} ${
              eventPerson ? '(' + eventPerson + ')' : ''
            }`}</S.AdditionalScore>
          )}
          {highlightEvent?.matchEventTypeId === MATCH_EVENTS.RED_CARD && (
            <S.AdditionalScore>{`Rødt kort: ${eventTeam} ${
              eventPerson ? '(' + eventPerson + ')' : ''
            }`}</S.AdditionalScore>
          )}
          {highlightEvent?.matchEventTypeId === MATCH_EVENTS.PENALTY && (
            <S.AdditionalScore>{`Straffe: ${eventTeam} ${
              eventPerson ? '(' + eventPerson + ' tar straffen)' : ''
            }`}</S.AdditionalScore>
          )}
          {highlightEvent?.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS && (
            <S.AdditionalScore>{`Straffebom: ${eventTeam} ${
              eventPerson ? '(' + eventPerson + ' tar straffen)' : ''
            }`}</S.AdditionalScore>
          )}

          {(match?.sportId === 1 || match?.sportId === 3) &&
            match.result &&
            Object.prototype.hasOwnProperty.call(
              match.result,
              'homeScore120',
            ) && (
              <S.AdditionalScore>{`Ordinær tid: ${match?.result?.homeScore90} - ${match?.result?.awayScore90}`}</S.AdditionalScore>
            )}

          {match?.sportId === 2 &&
            match.result &&
            Object.prototype.hasOwnProperty.call(
              match?.result,
              'homeScorePenalties',
            ) && <S.AdditionalScore>{`Etter straffer`}</S.AdditionalScore>}

          {match?.sportId === 2 &&
            match.result &&
            Object.prototype.hasOwnProperty.call(
              match?.result,
              'homeScore120',
            ) &&
            !Object.prototype.hasOwnProperty.call(
              match?.result,
              'homeScorePenalties',
            ) && <S.AdditionalScore>{`Etter sudden death`}</S.AdditionalScore>}

          {match?.sportId === 1 && match?.matchRelation?.matchNumber === 1 && (
            <S.AdditionalScore>{`Kamp 1 av 2`}</S.AdditionalScore>
          )}

          {match?.sportId !== 1 &&
            match?.matchRelation &&
            Object.prototype.hasOwnProperty.call(
              match?.matchRelation,
              'matchNumber',
            ) &&
            Object.prototype.hasOwnProperty.call(
              match?.matchRelation,
              'totalMatches',
            ) && (
              <S.AdditionalScore>
                {`Kamp ${match?.matchRelation?.matchNumber} av ${match?.matchRelation?.totalMatches}. Sammenlagt: ${match?.matchRelation?.score}`}
              </S.AdditionalScore>
            )}

          {match?.sportId === 1 &&
            match?.matchRelation &&
            match?.matchRelation?.matchNumber === 2 && (
              <S.AdditionalScore>{`Kamp 2 av 2. Sammenlagt: ${
                match?.matchRelation?.aggregatedHomeGoalsExtraTime ||
                match?.matchRelation?.aggregatedHomeGoals
              } - ${
                match?.matchRelation?.aggregatedAwayGoalsExtraTime ||
                match?.matchRelation?.aggregatedAwayGoals
              }`}</S.AdditionalScore>
            )}
          {match?.sportId === 1 &&
            match.result &&
            Object.prototype.hasOwnProperty.call(
              match?.result,
              'homeScorePenalties',
            ) && (
              <S.AdditionalScore>{`Straffer: ${match?.result?.homeScorePenalties} - ${match?.result?.awayScorePenalties}`}</S.AdditionalScore>
            )}
        </S.AdditionalScores>

        <S.HomeTeam>
          <S.TeamName>
            <ClubLogo
              src={match.homeTeam?.logo?.url}
              alt={match.homeTeam.name}
              size="xs"
              name={
                <Highlight query={searchQuery} styles={{ bg: 'orange.300' }}>
                  {match.homeTeam.name}
                </Highlight>
              }
              displayFlag={
                match.stage.tournament.tournamentTypeId === 1 ||
                match.stage.tournament.tournamentTypeId === 5
              }
              countryName={match.homeTeam.name}
            />
          </S.TeamName>
        </S.HomeTeam>
        <S.AwayTeam>
          <S.TeamName>
            <ClubLogo
              src={match.awayTeam?.logo?.url}
              alt={match.awayTeam.name}
              size="xs"
              name={
                <Highlight query={searchQuery} styles={{ bg: 'orange.300' }}>
                  {match.awayTeam.name}
                </Highlight>
              }
              displayFlag={
                match.stage.tournament.tournamentTypeId === 1 ||
                match.stage.tournament.tournamentTypeId === 5
              }
              countryName={match.awayTeam.name}
            />
          </S.TeamName>
        </S.AwayTeam>
      </S.Match>

      <S.Info
        aria-label={
          (match && match.tvChannels && match?.tvChannels?.length !== 0) ||
          (match && match.liveFeeds && match?.liveFeeds?.length !== 0)
            ? 'Info'
            : ''
        }
      >
        {MATCH_TYPE_NAME[match.matchTypeId] && (
          <S.MatchType>{MATCH_TYPE_NAME[match.matchTypeId]}</S.MatchType>
        )}
        {matchStreams &&
          matchStreams?.map((stream: any) => (
            <Fragment key={stream?.id}>
              {!stream?.radio && (
                <S.Stream>
                  <img
                    width="16px"
                    src="https://sportsdata-widgets.ntb.no/logo/direktesport.png"
                  />
                  Direktesport
                </S.Stream>
              )}
              {stream?.radio && (
                <S.Stream>
                  <img
                    width="16px"
                    src="https://sportsdata-widgets.ntb.no/logo/untold.png"
                  />
                  Untold
                </S.Stream>
              )}
            </Fragment>
          ))}
        {match?.tvChannels && (
          <S.TVChannel
            $showDivider={
              Boolean(MATCH_TYPE_NAME[match.matchTypeId]) ||
              Boolean(matchStreams?.length)
            }
          >
            {match?.tvChannels?.[0]?.name}
          </S.TVChannel>
        )}
        {match?.liveFeeds && showComments && isAllowedToShowLiveReport && (
          <S.Hosted
            $showDivider={
              match?.tvChannels ||
              MATCH_TYPE_NAME[match.matchTypeId] ||
              Boolean(matchStreams?.length)
            }
          >
            Kampen dekkes av reporter
          </S.Hosted>
        )}
      </S.Info>

      {(onClickSubscribeMatch || (isLeague && onClickStandings)) && (
        <S.Settings data-element-id={ELEMENT_IDS.BUTTON}>
          <Menu>
            <MenuButton
              as={IconButton}
              size="xs"
              aria-label="Åpne valg for kamp"
              icon={<TbDots />}
              borderRadius="var(--ntb-radii-md)"
              variant="outline"
              backgroundColor="var(--background-color)"
              borderColor="var(--border-color)"
              color="var(--font-color)"
              _hover={{
                backgroundColor: 'var(--background-color__hover)',
                borderColor: 'var(--border-color__hover)',
                color: 'var(--font-color__hover)',
              }}
              _active={{
                backgroundColor: 'var(--background-color__active)',
                borderColor: 'var(--border-color__active)',
                color: 'var(--font-color__active)',
              }}
            />
            <MenuList zIndex={9999}>
              {onClickSubscribeMatch && (
                <MenuItem
                  onClick={() => onClickSubscribeMatch(match)}
                  icon={
                    subscribedMatches?.find(
                      (subscribedMatch: any) =>
                        subscribedMatch.id === match?.id,
                    ) ? (
                      <MinusIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  {subscribedMatches?.find(
                    (subscribedMatch: any) => subscribedMatch.id === match?.id,
                  )
                    ? 'Fjern fra mine kamper'
                    : 'Legg til i mine kamper'}
                </MenuItem>
              )}
              {isLeague && (
                <MenuItem
                  aria-label={`Åpne tabell for ${match?.stage?.name}`}
                  onClick={() => {
                    onClickStandings({
                      tournamentId: match?.stage?.tournament?.id,
                      stageId: match?.stage?.id,
                      seasonId: match?.stage?.yearEnd,
                    });
                  }}
                  icon={<StandingsIcon2 w={3} h={3} />}
                >
                  Tabell
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </S.Settings>
      )}
    </S.Wrapper>
  );
};

export default Match;
