export const CUSTOMER_NAMES = {
  1: 'amedia',
  3: 'tv2',
  4: 'nettavisen',
  5: 'dagbladet',
  6: 'nifs',
  7: 'livesporten',
  13: 'nrk',
};

export const CUSTOMER_CONCERN_IDS = {
  AMEDIA: 1,
  TV2: 3,
  NETTAVISEN: 4,
  DAGBLADET: 5,
  NIFS: 6,
  NRK: 13,
};

export const NEO_CUSTOMER_UUIDS = {
  NRK: '54d017c7-e541-4ac2-a6f3-f53db92d7a74',
};
