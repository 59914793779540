import styled, { css } from 'styled-components';

import { MATCH_STATUS, MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $isHomeTeam?: boolean;
  $capitalize?: boolean;
  $highlightWinningTeam?: boolean;
  $isWinningTeam?: boolean;
  $isClickable?: boolean;
  $isTeamWinning?: boolean;
  $isTeamMatches?: boolean;
  $isMatchInProgress?: boolean;
  $resultType?: string;
  $matchStatusId?: number;
  $displayBorder?: boolean;
}

export const Match = styled.li<StyledProps>`
  container-type: inline-size;
  container-name: container;
  display: grid;
  grid-template-areas: 'date date date' '. matchStatus .' 'homeTeam result awayTeam' 'additionalResults additionalResults additionalResults' 'stadium stadium stadium';
  grid-template-columns: 2fr 1fr 2fr;
  grid-row-gap: var(--ntb-space-1);
  justify-items: center;
  align-items: center;
  padding: var(--ntb-space-3) var(--ntb-space-1);

  background-color: var(--background-color);
  color: var(--font-color);

  box-shadow: ${(props) =>
    props?.$displayBorder && `inset 0px -1px 0px var(--border-color)`};

  &:last-child {
    box-shadow: none;
  }
`;

export const DateInfo = styled.div<StyledProps>`
  width: 100%;
  background-color: var(--background-color);
  color: var(--font-color);
  grid-area: date;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateAndName = styled.div`
  display: flex;
  column-gap: var(--ntb-space-0-5);
`;

export const StageName = styled.div`
  justify-self: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
`;

export const MatchDate = styled.div`
  justify-self: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const MatchNumber = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
`;

export const MatchStatusContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-area: ${(props) =>
    props?.$isMatchInProgress ? 'matchStatus' : 'result'};
`;

export const MatchStatus = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  border-radius: var(--ntb-radii-md);

  padding: var(--ntb-space-1) var(--ntb-space-2);
  cursor: pointer;
  text-align: center;

  ${(props) =>
    (props?.$matchStatusId === MATCH_STATUS.NOT_STARTED ||
      props?.$matchStatusId === MATCH_STATUS.DATE_NOT_SET) &&
    css`
      background-color: var(--result-background-color__not-started);
      color: var(--result-font-color__not-started);
    `}

  ${(props) =>
    (props?.$matchStatusId === MATCH_STATUS.POSTPONED ||
      props?.$matchStatusId === MATCH_STATUS.CANCELLED ||
      props?.$matchStatusId === MATCH_STATUS.ABANDONED) &&
    css`
      background-color: var(--result-background-color__not-set);
      color: var(--base-colors-error);
    `}
`;

export const ResultWrapper = styled.div`
  grid-area: result;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResultContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Result = styled.div<StyledProps>`
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--font-color);

  border-radius: ${(props) =>
    props?.$isTeamMatches
      ? 'var(--ntb-radii-md) 0 0 var(--ntb-radii-md)'
      : 'var(--ntb-radii-md)'};
  padding: var(--ntb-space-1) var(--ntb-space-2);

  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
    `}

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const TeamResult = styled.div<StyledProps>`
  background: ${(props) =>
    props?.$resultType === 'S'
      ? 'var(--base-colors-success)'
      : props?.$resultType === 'U'
        ? 'var(--team-result-bg__draw)'
        : 'var(--base-colors-error)'};
  border-radius: 0px var(--ntb-radii-md) var(--ntb-radii-md) 0;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  color: var(--team-result-font-color);
  display: flex;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const AOT = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const AdditionalResults = styled.div`
  grid-area: additionalResults;
  justify-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  column-gap: var(--ntb-space-1);
`;

export const ResultFullTime = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const ResultPenalties = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const ResultCombined = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const NoResult = styled.p`
  padding: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-sm);
`;

export const StadiumWrapper = styled.div`
  grid-area: stadium;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-bold);
`;
