import { COMPONENT_IDS, MATCH_STATUS_ID } from '@ntb-sport/constants';

import * as S from './MatchTimer.styled';
import { displayClock, getIsOvertime } from './utils';
import { useMatchTimer } from './hooks';

interface MatchTimerProps {
  showSeconds?: boolean;
  timeStart: string | Date;
  matchStatusId: MATCH_STATUS_ID;
}

enum MATCH_CLOCK_STATE {
  OVERTIME = 'overtime',
  REGULAR = 'regular',
}

const MATCH_STATUS_ID_ON_PROGRESS_TEXT = {
  [MATCH_STATUS_ID.ONGOING]: 'Pågår',
  [MATCH_STATUS_ID.FIRST_HALF]: '1. omg',
  [MATCH_STATUS_ID.SECOND_HALF]: '2. omg',
  [MATCH_STATUS_ID.FIRST_HALF_EXTRA_TIME]: '1. ekstraomg',
  [MATCH_STATUS_ID.SECOND_HALF_EXTRA_TIME]: '2. ekstraomg',
};

export const MatchTimer = ({
  showSeconds = false,
  timeStart,
  matchStatusId,
}: MatchTimerProps) => {
  const { minutes, seconds } = useMatchTimer({ timeStart, matchStatusId });
  const isOverTime = getIsOvertime(matchStatusId, minutes);

  if (!timeStart) {
    console.log(
      'timeStart not provided. Fallback to matchStatusId',
      MATCH_STATUS_ID[matchStatusId],
      matchStatusId,
    );
    return (
      <S.MatchStatus
        data-component-id={COMPONENT_IDS.MATCH_TIMER}
        data-element-id="match-status"
      >
        {
          MATCH_STATUS_ID_ON_PROGRESS_TEXT[
            matchStatusId as keyof typeof MATCH_STATUS_ID_ON_PROGRESS_TEXT
          ]
        }
      </S.MatchStatus>
    );
  }

  if (!displayClock(matchStatusId)) {
    console.log(
      'Match is not in progress',
      MATCH_STATUS_ID[matchStatusId],
      matchStatusId,
    );
    return null;
  }

  return (
    <S.MatchTimer
      aria-live="off"
      $isOvertime={isOverTime}
      data-component-id={COMPONENT_IDS.MATCH_TIMER}
      data-status-id={
        isOverTime ? MATCH_CLOCK_STATE.OVERTIME : MATCH_CLOCK_STATE.REGULAR
      }
    >
      {showSeconds
        ? `${minutes}:${('0' + seconds).slice(-2)}`
        : `${minutes + 1}'`}
    </S.MatchTimer>
  );
};

export default MatchTimer;
