import {
  SPORT_GROUP,
  SPORT_GROUP_TO_NIFS_SPORT_ID,
} from '@ntb-sport/constants';
import { NifsStage } from '@ntb-sport/types';

import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsTournamentStages {
  apiBaseUrl: string;
  sportGroup: string;
  tournamentId: number;
}

export const fetchNifsTournamentStages = async ({
  apiBaseUrl,
  sportGroup,
  tournamentId,
}: FetchNifsTournamentStages): Promise<NifsStage[]> => {
  const url =
    sportGroup === SPORT_GROUP.FOOTBALL
      ? `${apiBaseUrl}/tournaments/${tournamentId}/stages/`
      : `${apiBaseUrl}/nifTournaments/${tournamentId}/nifStages/?sportId=${
          SPORT_GROUP_TO_NIFS_SPORT_ID[
            sportGroup as keyof typeof SPORT_GROUP_TO_NIFS_SPORT_ID
          ]
        }`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
