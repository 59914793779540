export const ROUND_TYPE_GROUPS = {
  'round-type-final': [
    'round-type-final',
    'round-type-bronze-final',
    'round-type-fifth-place-decider',
    'round-type-seventh-place-decider',
  ],
  'round-type-semi-final': [
    'round-type-semi-final',
    'round-type-placement-semi-final',
  ],
  'round-type-quarter-final': ['round-type-quarter-final'],
  'round-type-round-of-16': ['round-type-round-of-16'],
  'round-type-qualification': ['round-type-qualification'],
  'round-type-preliminary': ['round-type-preliminary'],
  'round-type-first-round': ['round-type-first-round'],
  'round-type-repechage': ['round-type-repechage'],
};

export const ROUND_TYPE_GROUP = {
  'round-type-final': 'round-type-final',
  'round-type-bronze-final': 'round-type-final',
  'round-type-fifth-place-decider': 'round-type-final',
  'round-type-seventh-place-decider': 'round-type-final',
  'round-type-semi-final': 'round-type-semi-final',
  'round-type-placement-semi-final': 'round-type-semi-final',
  'round-type-quarter-final': 'round-type-quarter-final',
  'round-type-round-of-16': 'round-type-round-of-16',
  'round-type-qualification': 'round-type-qualification',
  'round-type-preliminary': 'round-type-preliminary',
  'round-type-first-round': 'round-type-first-round',
  'round-type-repechage': ['round-type-repechage'],
};

export const ROUND_TYPE_GROUP_NAME = {
  'round-type-final': 'Finaler',
  'round-type-bronze-final': 'Finaler',
  'round-type-fifth-place-decider': 'Finaler',
  'round-type-seventh-place-decider': 'Finaler',
  'round-type-semi-final': 'Semifinaler',
  'round-type-placement-semi-final': 'Semifinaler',
  'round-type-quarter-final': 'Kvartfinaler',
  'round-type-round-of-16': '8-delsfinaler',
  'round-type-qualification': 'Kvalifisering',
  'round-type-preliminary': 'Innledende',
  'round-type-first-round': 'Forsøk',
  'round-type-repechage': 'Oppsamling',
};
