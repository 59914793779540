import { NifsMatch } from '@ntb-sport/types';

interface MatchGroupDate {
  date: string;
  key: string | number;
  matches: NifsMatch[];
}

interface MatchGroupDateMap {
  [key: string]: MatchGroupDate;
}

export interface GroupFunctionParams {
  matches: NifsMatch[];
  sortDirection?: 'ascending' | 'descending';
}

export const groupMatchesByDate = ({
  matches,
  sortDirection = 'descending',
}: GroupFunctionParams): MatchGroupDate[] => {
  const groups = matches.reduce(
    (accumulator: MatchGroupDateMap, match: NifsMatch) => {
      const date = match?.timestamp && match.timestamp.split('T')[0];

      if (date) {
        if (!accumulator[date]) {
          accumulator[date] = {
            date,
            key: date,
            matches: [],
          };
        }

        accumulator[date].matches.push(match);
      }

      return accumulator;
    },
    {},
  );

  const sortedGroups = (Object.values(groups) as MatchGroupDate[]).sort(
    (a, b) => {
      if (sortDirection === 'ascending') {
        return a.date > b.date ? -1 : 0;
      }
      if (sortDirection === 'descending') {
        return a.date < b.date ? -1 : 0;
      }

      return 0;
    },
  );

  return sortedGroups;
};
