import { useBem } from '@ntb-sport/hooks';
import './active-athlete-participant.css';
import CountryFlag from '../../../common/CountryFlag/CountryFlag';
import classNames from 'classnames';
import { SporteditActiveAthleteParticipant } from '@ntb-sport/types';

export const ActiveAthleteParticipant = ({
  eventParticipant,
  isCurrentEventParticipant = false,
}: {
  eventParticipant: SporteditActiveAthleteParticipant;
  isCurrentEventParticipant?: boolean;
}) => {
  const bem = useBem('active-athlete-participant');
  const current = (isCurrentEventParticipant && 'current') || '';

  const getCountryName = (
    names: { name: string; nameTypeId: number }[],
  ): string | undefined => names?.find((name) => name?.nameTypeId === 25)?.name;

  const countryCode =
    getCountryName(eventParticipant.person.countries?.[0]?.names || []) ||
    getCountryName(eventParticipant.person.country?.names || []);

  return (
    <div className={classNames(bem('', current))}>
      <div className={classNames(bem('person', current))}>
        <span className={bem('bibNumber')}>{eventParticipant.bibNumber}</span>

        <p>{eventParticipant?.person?.firstName}</p>
        <span className={bem('lastName')}>
          {eventParticipant?.person?.lastName}
        </span>
      </div>
      <div className={classNames(bem('country', current))}>
        <CountryFlag countryCode={countryCode} />
        {eventParticipant.person.countries?.[0]?.name ||
          eventParticipant.person.country?.name}
      </div>
    </div>
  );
};
