import { NifsReferee } from '@ntb-sport/types';

import CountryFlag from '../../common/CountryFlag/CountryFlag';
import Gender from '../../common/Gender/Gender';

import {
  groupRefereesByType,
  RefereeGroup,
} from './helpers/groupRefereesByType';

import * as S from './Referees.styled';

export const Referees = ({ referees }: { referees: NifsReferee[] }) => {
  const refereesByType = groupRefereesByType(referees);

  const groupsWithReferees = (
    Object.values(refereesByType) as RefereeGroup[]
  ).filter((refereeGroup) => {
    return refereeGroup.referees.length > 0;
  });

  return (
    <>
      {groupsWithReferees.map((refereeGroup, i) => (
        <div key={i}>
          {refereeGroup.name && <S.Header>{refereeGroup.name}</S.Header>}

          {refereeGroup.referees.map((referee: NifsReferee) => (
            <S.Referee key={referee.id}>
              <S.RefereeName>
                {referee.name} <Gender gender={referee?.gender} />
              </S.RefereeName>
              <S.RefereeCountry>
                <CountryFlag countryName={referee.country?.name} />
                <span>{referee.country?.name}</span>
              </S.RefereeCountry>
            </S.Referee>
          ))}
        </div>
      ))}
    </>
  );
};
