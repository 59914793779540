import { FormIcon, Placement, Team } from '../Table.styled';

import React from 'react';
import Table from '../Table';
import { MatchesType } from '@ntb-sport/types';
import ClubLogo from '../../ClubLogo/ClubLogo';

const resultString = {
  1: 'Seier',
  2: 'Tap',
  3: 'Uavgjort',
};

export const TeamStandingsFootball = ({
  data,
  isLoading,
  matches,
  hasAveragePoints,
  relegationLineTeamId,
  relegationPlayoffLineTeamId,
  promotionLineTeamId,
  promotionPlayoffLineTeamId,
  hasFormData,
  teamLink,
  highlightedTeams,
  tableCaption,
}: {
  data: any;
  isLoading: any;
  matches: MatchesType;
  hasAveragePoints: any;
  relegationLineTeamId: any;
  relegationPlayoffLineTeamId: any;
  promotionLineTeamId: any;
  promotionPlayoffLineTeamId: any;
  hasFormData: any;
  teamLink: any;
  highlightedTeams?: number[];
  tableCaption?: string;
}) => {
  const tableData = React.useMemo(() => data?.teams, [data]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'place',
        meta: {
          ariaLabel: 'Plassering',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $collapse: 'all',
        },
        Cell: (props: any) => {
          const rowType =
            data.tableDividingLines &&
            data.tableDividingLines.find(
              (item: any) =>
                props.row.original.place >= item.fromPlace &&
                props.row.original.place <= item.toPlace,
            );
          const rowTypeId = rowType && rowType.typeId;
          return (
            <Placement $rowTypeId={rowTypeId}>
              {props.row.original.place}
            </Placement>
          );
        },
      },
      {
        Header: 'Lag',
        accessor: 'name',
        meta: {
          ariaLabel: 'Lag',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        Cell: (props: any) => {
          return (
            <Team>
              {teamLink ? (
                teamLink({
                  sportId: 1,
                  id: props.row.original.id,
                  children: (
                    <ClubLogo
                      src={props?.row?.original?.logo?.url}
                      alt=""
                      size="xs"
                      name={
                        !matches?.tablet
                          ? props?.row?.original?.shortName ||
                            props?.row?.original?.name
                          : props?.row?.original?.name
                      }
                    />
                  ),
                })
              ) : (
                <ClubLogo
                  src={props?.row?.original?.logo?.url}
                  alt=""
                  name={
                    !matches?.tablet
                      ? props?.row?.original?.shortName ||
                        props?.row?.original?.name
                      : props?.row?.original?.name
                  }
                />
              )}
            </Team>
          );
        },
        styles: {
          $justifyContent: 'left',
        },
      },
      {
        Header: 'K',
        accessor: 'played',
        meta: {
          ariaLabel: 'Kamper',
        },
        isVisible: true,
        isSortable: matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
        },
      },
      {
        Header: 'S',
        accessor: 'won',
        meta: {
          ariaLabel: 'Seier',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $divider: true,
        },
      },
      {
        Header: 'U',
        accessor: 'draw',
        meta: {
          ariaLabel: 'Uavgjort',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
        },
      },
      {
        Header: 'T',
        accessor: 'lost',
        meta: {
          ariaLabel: 'Tap',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
        },
      },
      {
        Header: '+',
        accessor: 'goalsScored',
        meta: {
          ariaLabel: 'Scorede mål',
        },
        isVisible: matches?.tablet,
        isSortable: matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
        },
      },
      {
        Header: '-',
        accessor: 'goalsConceded',
        meta: {
          ariaLabel: 'Innslupne mål',
        },
        isSortable: matches?.tablet,
        isVisible: matches?.tablet,
        styles: {
          $textAlign: 'center',
        },
      },
      {
        Header: '+/-',
        accessor: 'goalDifference',
        meta: {
          ariaLabel: 'Målforskjell',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $divider: !matches?.tablet,
        },
      },
      {
        Header: 'PS',
        accessor: 'averagePoints',
        meta: {
          ariaLabel: 'Poengsnitt',
        },
        isSortable: matches?.tablet,
        isVisible: hasAveragePoints,
        styles: {
          $textAlign: 'center',
          $divider: true,
          $highlight: true,
        },
      },
      {
        Header: 'P',
        accessor: 'points',
        meta: {
          ariaLabel: 'Poeng',
        },
        isSortable: matches?.tablet,
        isVisible: !hasAveragePoints || matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
          $highlight: !hasAveragePoints,
        },
      },
      {
        Header: 'Form',
        accessor: 'form',
        meta: {
          ariaLabel: 'Form siste kamper',
        },
        isSortable: false,
        isVisible: hasFormData && matches?.tablet,
        Cell: (props: any) =>
          hasFormData &&
          typeof props.row.original.lastSixMatches === 'string' &&
          props.row.original.lastSixMatches
            .split(',')
            .map((result: number, index: any) => (
              <FormIcon
                aria-label={resultString[result as keyof typeof resultString]}
                key={index}
                size="xsmall"
                $result={Number(result)}
              />
            )),
        styles: {
          $divider: true,
          $collapse: 'all',
          $justifyContent: 'left',
        },
      },
    ];
  }, [matches]);

  return (
    <Table
      tableCaption={tableCaption}
      columns={columns}
      data={tableData}
      splitPlace={data?.splitPlace}
      splitRound={data?.splitRound}
      isLoading={isLoading}
      hasFormData={hasFormData}
      relegationLineTeamId={relegationLineTeamId}
      relegationPlayoffLineTeamId={relegationPlayoffLineTeamId}
      promotionLineTeamId={promotionLineTeamId}
      promotionPlayoffLineTeamId={promotionPlayoffLineTeamId}
      highlightedTeams={highlightedTeams}
    />
  );
};

export default TeamStandingsFootball;
