import { UUIDs } from 'neo-common-enums';
import { useMemo } from 'react';

import { COMPONENT_IDS, SPORT_GROUP, WINTERSPORTS } from '@ntb-sport/constants';

import { sortByAscending } from '@ntb-sport/helpers';

import { useBem } from '@ntb-sport/hooks';

import { NeoEventType } from '@ntb-sport/types';

import { getEventsGroupedBySport } from './helpers/getEventsGroupedBySport';
import { Sport } from './Sport/Sport';
import { SportType } from './types';

import './sportedit-calendar.css';

export const SportEditCalendar = ({
  events,
  sportGroup,
  onClick,
  date,
  eventParticipantsByCountryAndDate,
}: {
  events: NeoEventType[];
  sportGroup: string;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    date,
    sportId,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
  date: string;
  isLoading?: boolean;
  eventParticipantsByCountryAndDate?: any;
}) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);

  const eventsGroupedBySport = useMemo<SportType[]>(
    () =>
      getEventsGroupedBySport({
        events,
        date,
        eventParticipantsByCountryAndDate,
      }),
    [events, date],
  );

  if (!eventsGroupedBySport) return null;

  if (!Object?.keys(eventsGroupedBySport)?.length) {
    return <div className={bem('no-events')}>Ingen konkurranser funnet</div>;
  }

  return (
    <div className={bem()}>
      {Object.values(eventsGroupedBySport)
        ?.filter(({ uuid }: { uuid: string }) =>
          sportGroup === SPORT_GROUP.WINTERSPORTS
            ? WINTERSPORTS?.includes(uuid as UUIDs.Sport)
            : uuid === `sport-${sportGroup}`,
        )
        ?.sort((a: SportType, b: SportType) =>
          sortByAscending(a?.priority, b?.priority),
        )
        ?.map((sport: SportType) => (
          <Sport
            key={sport?.uuid}
            sport={sport}
            date={date}
            onClick={onClick}
          />
        ))}
    </div>
  );
};

export default SportEditCalendar;
