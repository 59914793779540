export const MATCH_EVENTS = {
  COMMENT: 1,
  GOAL: 2,
  RED_CARD: 3,
  YELLOW_CARD: 4,
  ASSIST: 5,
  OFFSIDE: 6,
  OWN_GOAL: 8,
  PENALTY: 9,
  PENALTY_GOAL: 10,
  PENALTY_MISS: 11,
  SECOND_YELLOW_CARD: 12,
  DISALLOWED_GOAL: 13,
  MATCH_KICK_OFF: 14,
  HALF_TIME: 15,
  MATCH_ENDS: 16,
  SECOND_HALF_ENDS_EXTRA_TIME: 17,
  HALF_TIME_EXTRA_TIME: 18,
  PENALTY_SHOOTOUT: 19,
  END_SECOND_HALF_EXTRA_TIME: 20,
  PLAYER_SUBSTITUTED: 23,
  SUBSTITUTE_ON: 24,
  CORNER: 25,
  START_SECOND_HALF: 26,
  CORNER_GOAL: 27,
  START_FIRST_HALF_EXTRA_TIME: 28,
  START_SECOND_HALF_EXTRA_TIME: 29,
  GOAL_PENALTY_SHOOTOUT: 30,
  MISS_PENALTY_SHOOTOUT: 31,
  PENALTY_PENALTIES_SHOOTOUT: 32,
  SCORE_CHANGED: 33,
  FREE_KICK: 41,
  LINEUP_READY: 43,
  WITHDRAWN_RED_CARD: 44,
  WITHDRAWN_YELLOW_CARD: 45,
  WITHDRAWN_SECOND_YELLOW_CARD: 46,
  SECOND_YELLOW_CARD_CHANGED_TO_FIRST_YELLOW_CARD: 47,
  INDIRECT_ASSIST: 48,
  TV2_VIDEO: 49,
  TWO_MINUTES_SUSPENSION: 100,
  FIVE_MINUTES_SUSPENSION: 101,
  TEN_MINUTES_SUSPENSION: 102,
  SECOND_HALF_TIME_EXTRA_TIME: 104,
  START_THIRD_HALF_EXTRA_TIME: 105,
  THIRD_HALF_TIME_EXTRA_TIME: 106,
  START_FOURTH_HALF_EXTRA_TIME: 107,
  SECOND_HALF_TIME: 110,
  START_THIRD_HALF: 111,
  BLUE_CARD: 112,
  MATCH_PENALTY: 113,
  FOURTH_HALF_TIME_EXTRA_TIME: 114,
  START_FIFTH_HALF_EXTRA_TIME: 115,
  FIFTH_HALF_TIME_EXTRA_TIME: 116,
  START_SIXTH_HALF_EXTRA_TIME: 117,
  SIXTH_HALF_TIME_EXTRA_TIME: 118,
  START_SEVENTH_HALF_EXTRA_TIME: 119,
  SEVENTH_HALF_TIME_EXTRA_TIME: 120,
  START_EIGHTH_HALF_EXTRA_TIME: 121,
  VAR: 123,
  PENALTY_OFFENCE: 124,
  PENALTY_FOULED: 125,
  ADDED_TIME: 126,
  PENALTY_SAVE: 130,
  SUMMARY: 500,
  HIGHLIGHT_VIDEO: 667,
};

export enum MATCH_STATUS_ID {
  PLAYED = 1,
  NOT_STARTED = 2,
  POSTPONED = 3,
  ABANDONED = 4,
  WILL_NOT_BE_PLAYED = 5,
  DATE_NOT_SET = 6,
  ONGOING = 7,
  FIRST_HALF = 8,
  HALF_TIME = 9,
  SECOND_HALF = 10,
  FIRST_HALF_EXTRA_TIME = 11,
  SECOND_HALF_EXTRA_TIME = 12,
  PENALTY_SHOOTOUT = 13,
  PAUSE_BEFORE_EXTRA_TIME = 14,
  HALF_TIME_EXTRA_TIME = 15,
  CANCELLED = 16,
  SECOND_HALF_TIME_EXTRA_TIME = 18,
  THIRD_HALF_EXTRA_TIME = 19,
  THIRD_HALF_TIME_EXTRA_TIME = 20,
  FOURTH_HALF_EXTRA_TIME = 21,
  THIRD_HALF = 22,
  FOURTH_HALF_TIME_EXTRA_TIME = 23,
  FIFTH_HALF_EXTRA_TIME = 24,
  FIFTH_HALF_TIME_EXTRA_TIME = 25,
  SIXTH_HALF_EXTRA_TIME = 26,
  SIXTH_HALF_TIME_EXTRA_TIME = 27,
  SEVENTH_HALF_EXTRA_TIME = 28,
  SEVENTH_HALF_TIME_EXTRA_TIME = 29,
  EIGHTH_HALF_EXTRA_TIME = 30,
  SECOND_HALF_TIME = 31,
  TABLE_SIMULATOR_PLAYED = 600,
}

export const MATCH_STATUS = {
  PLAYED: 1,
  NOT_STARTED: 2,
  POSTPONED: 3,
  ABANDONED: 4,
  WILL_NOT_BE_PLAYED: 5,
  DATE_NOT_SET: 6,
  ONGOING: 7,
  FIRST_HALF: 8,
  HALF_TIME: 9,
  SECOND_HALF: 10,
  FIRST_HALF_EXTRA_TIME: 11,
  SECOND_HALF_EXTRA_TIME: 12,
  PENALTY_SHOOTOUT: 13,
  PAUSE_BEFORE_EXTRA_TIME: 14,
  HALF_TIME_EXTRA_TIME: 15,
  CANCELLED: 16,
  SECOND_HALF_TIME_EXTRA_TIME: 18,
  THIRD_HALF_EXTRA_TIME: 19,
  THIRD_HALF_TIME_EXTRA_TIME: 20,
  FOURTH_HALF_EXTRA_TIME: 21,
  THIRD_HALF: 22,
  FOURTH_HALF_TIME_EXTRA_TIME: 23,
  FIFTH_HALF_EXTRA_TIME: 24,
  FIFTH_HALF_TIME_EXTRA_TIME: 25,
  SIXTH_HALF_EXTRA_TIME: 26,
  SIXTH_HALF_TIME_EXTRA_TIME: 27,
  SEVENTH_HALF_EXTRA_TIME: 28,
  SEVENTH_HALF_TIME_EXTRA_TIME: 29,
  EIGHTH_HALF_EXTRA_TIME: 30,
  SECOND_HALF_TIME: 31,
  TABLE_SIMULATOR_PLAYED: 600,
};

export const MATCH_TYPE = {
  FINAL: 1,
  BRONZE_FINAL: 2,
  SEMI_FINAL: 3,
  QUARTER_FINAL: 4,
  ROUND_OF_16: 5,
  ROUND_OF_32: 25,
};

export const MATCH_TYPE_NAME = {
  [MATCH_TYPE.FINAL]: 'Finale',
  [MATCH_TYPE.SEMI_FINAL]: 'Semifinale',
  [MATCH_TYPE.BRONZE_FINAL]: 'Bronsefinale',
  [MATCH_TYPE.QUARTER_FINAL]: 'Kvartfinale',
  [MATCH_TYPE.ROUND_OF_16]: '8-delsfinale',
  [MATCH_TYPE.ROUND_OF_32]: '16-delsfinale',
  round1: 'Runde 1',
  round2: 'Runde 2',
  round3: 'Runde 3',
  round4: 'Runde 4',
  round5: 'Runde 5',
  round6: 'Runde 6',
  round7: 'Runde 7',
  round8: 'Runde 8',
  round9: 'Runde 9',
  round10: 'Runde 10',
  round11: 'Runde 11',
  round12: 'Runde 12',
  round13: 'Runde 13',
  round14: 'Runde 14',
  round15: 'Runde 15',
  round16: 'Runde 16',
  round17: 'Runde 17',
  round18: 'Runde 18',
  round19: 'Runde 19',
  round20: 'Runde 20',
  round21: 'Runde 21',
  round22: 'Runde 22',
  round23: 'Runde 23',
  round24: 'Runde 24',
  round25: 'Runde 25',
  round26: 'Runde 26',
  round27: 'Runde 27',
  round28: 'Runde 28',
  round29: 'Runde 29',
  round30: 'Runde 30',
  round31: 'Runde 31',
  round32: 'Runde 32',
  round33: 'Runde 33',
  round34: 'Runde 34',
  round35: 'Runde 35',
  round36: 'Runde 36',
  round37: 'Runde 37',
  round38: 'Runde 38',
  round39: 'Runde 39',
  round40: 'Runde 40',
  round41: 'Runde 41',
  round42: 'Runde 42',
  round43: 'Runde 43',
  round44: 'Runde 44',
  round45: 'Runde 45',
  round46: 'Runde 46',
  round47: 'Runde 47',
  round48: 'Runde 48',
  round49: 'Runde 49',
};
