import {
  Row,
  Cell,
  DividerCell,
  DividerRow,
  HeaderCell,
  SortArrowDown,
  SortArrowUp,
  SortArrows,
  StyledTable,
  CellButton,
} from './Table.styled';
import { useSortBy, useTable } from 'react-table';

import { Fragment } from 'react';

import { ELEMENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

const Table = ({
  columns,
  data,
  isLoading,
  relegationLineTeamId,
  relegationPlayoffLineTeamId,
  promotionLineTeamId,
  promotionPlayoffLineTeamId,
  splitPlace,
  splitRound,
  hasFormData,
  highlightedTeams,
  tableCaption,
}: {
  columns: any;
  data: any;
  isLoading: any;
  relegationLineTeamId: any;
  relegationPlayoffLineTeamId?: any;
  promotionLineTeamId?: any;
  promotionPlayoffLineTeamId?: any;
  splitPlace?: any;
  splitRound?: any;
  hasFormData?: any;
  highlightedTeams?: number[];
  tableCaption?: string;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.reduce((hiddenColumns: any, column: any) => {
            if (!column.isVisible) {
              hiddenColumns.push(column.accessor);
            }
            return hiddenColumns;
          }, []),
        },
      },
      useSortBy,
    );

  return (
    <StyledTable {...getTableProps()}>
      <caption className="visually-hidden sr-only">
        {tableCaption ? `Sesongtabell for ${tableCaption}.` : 'Sesongtabell.'}
      </caption>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...headerGroupProps}>
              {headerGroup.headers.map((column: any) => {
                const { key, ...headerProps } = column.getHeaderProps(
                  column.getSortByToggleProps(),
                );

                delete headerProps.title;

                return (
                  <HeaderCell
                    key={key}
                    $isSorted={column.isSorted}
                    {...column.styles}
                    {...headerProps}
                    role="cell"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <CellButton
                        {...column.styles}
                        title={`${column.meta.ariaLabel}. ${
                          column.isSorted && column?.isSortedDesc
                            ? 'Synkende. '
                            : column.isSorted && !column?.isSortedDesc
                              ? 'Stigende.'
                              : 'Sortert stigende, standard.'
                        }`}
                      >
                        <span aria-hidden="true">
                          {column.render('Header')}
                        </span>

                        {column.isSortable && (
                          <SortArrows aria-hidden={true}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortArrowUp />
                              ) : (
                                <SortArrowDown />
                              )
                            ) : (
                              <>
                                <SortArrowUp />
                                <SortArrowDown />
                              </>
                            )}
                          </SortArrows>
                        )}
                      </CellButton>
                    )}
                  </HeaderCell>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          if (row.index === splitPlace) {
            return (
              <Fragment key={row.id}>
                <DividerRow>
                  <DividerCell colSpan={hasFormData ? 13 : 12}>
                    Serien deles etter {splitRound} runder.
                  </DividerCell>
                </DividerRow>
                <Row {...row.getRowProps()} data-element-id={ELEMENT_IDS.ROW}>
                  {row.cells.map((cell: any) => (
                    <Cell
                      key={cell.id}
                      aria-label={cell.column?.meta?.ariaLabel}
                      $isSorted={cell.column.isSorted}
                      $displayDividerBottom={
                        cell.row.original.id === promotionLineTeamId ||
                        cell.row.original.id === promotionPlayoffLineTeamId
                      }
                      $displayDividerTop={
                        cell.row.original.id === relegationLineTeamId ||
                        cell.row.original.id === relegationPlayoffLineTeamId
                      }
                      {...cell.column.styles}
                      {...cell.getCellProps()}
                    >
                      {isLoading ? <Skeleton /> : cell.render('Cell')}
                    </Cell>
                  ))}
                </Row>
              </Fragment>
            );
          }

          const { key, rowProps } = row.getRowProps();
          return (
            <Row
              data-element-id={ELEMENT_IDS.ROW}
              key={key}
              $highlightedTeam={highlightedTeams?.includes(row?.original?.id)}
              $displayDividerBottom={
                row.original.id === promotionLineTeamId ||
                row.original.id === promotionPlayoffLineTeamId
              }
              $displayDividerTop={
                row.original.id === relegationLineTeamId ||
                row.original.id === relegationPlayoffLineTeamId
              }
              {...rowProps}
            >
              {row.cells.map((cell: any) => {
                const { key, role } = cell.getCellProps();
                return (
                  <Cell
                    key={key}
                    role={role}
                    aria-label={cell.column?.meta?.ariaLabel}
                    $isSorted={cell.column.isSorted}
                    $displayDividerBottom={
                      cell.row.original.id === promotionLineTeamId ||
                      cell.row.original.id === promotionPlayoffLineTeamId
                    }
                    $displayDividerTop={
                      cell.row.original.id === relegationLineTeamId ||
                      cell.row.original.id === relegationPlayoffLineTeamId
                    }
                    $highlightedTeam={highlightedTeams?.includes(
                      row?.original?.id,
                    )}
                    {...cell.column.styles}
                  >
                    {isLoading ? <Skeleton /> : cell.render('Cell')}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
