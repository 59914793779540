export const QUERY_KEYS = {
  CUSTOMER_SETTINGS: 'CUSTOMER_SETTINGS',
  CUSTOMER_SETUP: 'CUSTOMER_SETUP',
  DAYS_WITH_EVENTS_IN_MONTH: 'DAYS_WITH_EVENTS_IN_MONTH',
  MATCH: 'MATCH',
  STADIUM: 'STADIUM',
  NIFS_COUNTRIES: 'NIFS_COUNTRIES',
  NIFS_FAIR_PLAY_TABLE: 'NIFS_FAIR_PLAY_TABLE',
  NIFS_TOURNAMENTS: 'NIFS_TOURNAMENTS',
  NIFS_TOURNAMENT_STAGES: 'NIFS_TOURNAMENT_STAGES',
  NIFS_STAGE_MATCHES: 'NIFS_STAGE_MATCHES',
  NIFS_MATCHES: 'NIFS_MATCHES',
  NIFS_TEAMS: 'NIFS_TEAMS',
  NIFS_TEAM: 'NIFS_TEAM',
  NIFS_TEAM_FIXTURES: 'NIFS_TEAM_FIXTURES',
  NIFS_TEAM_STAGES: 'NIFS_TEAM_STAGES',
  NIFS_STAGE_FIXTURES: 'NIFS_STAGE_FIXTURES',
  EVENTS: 'EVENTS',
  EVENT: 'EVENT',
  SPORT_COMPETITIONS: 'SPORT_COMPETITIONS',
  COMPETITION_SEASONS: 'COMPETITION_SEASONS',
  EVENT_STANDINGS: 'EVENT_STANDINGS',
  ATHLETICS_COMBINED_SCOPES: 'ATHLETICS_COMBINED_SCOPES',
  SCOPES: 'SCOPES',
  EVENT_PARTICIPANTS: 'EVENT_PARTICIPANTS',
  SCOPE_RESULTS: 'SCOPE_RESULTS',
  ALL_SCOPE_RESULTS: 'ALL_SCOPE_RESULTS',
  WORLD_CUP_STANDINGS: 'WORLD_CUP_STANDINGS',
  TOUR: 'TOUR',
  MEDAL_TABLES: 'MEDAL_TABLES',
  MEDAL_TABLE: 'MEDAL_TABLE',
  MEDAL_TABLE_COUNTRY: 'MEDAL_TABLE_COUNTRY',
  COMPETITIONS: 'COMPETITIONS',
  COMPETITION: 'COMPETITION',
  STAGES: 'STAGES',
  STAGE_STANDINGS: 'STAGE_STANDINGS',
  STAGE_FIXTURES: 'STAGE_FIXTURES',
  STAGE_STATISTICS: 'STAGE_STATISTICS',
  TEAMS: 'TEAMS',
  TEAM: 'TEAM',
  TEAM_FIXTURES: 'TEAM_FIXTURES',
  TEAM_STAGES: 'TEAM_STAGES',
  MATCH_EVENTS: 'MATCH_EVENTS',
  ATHLETES: 'ATHLETES',
  ATHLETE: 'ATHLETE',
  MATCH_VIDEOS: 'MATCH_VIDEOS',
  MATCH_EVENT_VIDEOS: 'MATCH_EVENT_VIDEOS',
  ALL_STANDINGS: 'ALL_STANDINGS',
  STANDINGS: 'STANDINGS',
  TRANSFERS: 'TRANSFERS',
  ALL_SHOT_SERIES: 'ALL_SHOT_SERIES',
  TOUR_STANDINGS: 'TOUR_STANDINGS',
  SEASON_STANDINGS: 'SEASON_STANDINGS',
  SEASON_STANDING: 'SEASON_STANDING',
  EVENT_PARTICIPANTS_BY_COUNTRY_AND_DATE:
    'EVENT_PARTICIPANTS_BY_COUNTRY_AND_DATE',
  SPORTS: 'SPORTS',
  SEASON_EVENTS: 'SEASON_EVENTS',
  NRK_VIDEOS: 'NRK_VIDEOS',
};

export const ELEMENT_MODIFIERS = {
  DEFAULT: 'default',
  HIGHLIGHT: 'highlight',
};

export const ELEMENT_IDS = {
  BUTTON: 'button',
  ICON: 'icon',
  CONTAINER: 'container',
  HEADER: 'header',
  FOOTER: 'footer',
  SUMMARY: 'summary',
  TOURNAMENT: 'tournament',
  COMPETITION: 'competition',
  SPORT: 'sport',
  STAGE: 'stage',
  MATCH: 'match',
  EVENT: 'event',
  LOGO: 'logo',
  IMAGE: 'image',
  TITLE: 'title',
  MENU: 'menu',
  LIST: 'list',
  LIST_ITEM: 'list-item',
  ROW: 'row',
  PITCH: 'pitch',
  OVERLAY: 'overlay',
  SCORE: 'score',
  TYPE_DEFAULT: 'default',
  TYPE_TIME: 'time',
  TYPE_SUCCESS: 'success',
  ['TYPE_SUCCESS-MINOR']: 'success-minor',
  TYPE_ERROR: 'error',
  ['TYPE_ERROR-MINOR']: 'error-minor',
  TYPE_HIGHLIGHT: 'highlight',
  TYPE_WARNING: 'warning',
};

export const COMPONENT_IDS = {
  LIVECENTER_HEADER: 'livecenter-header',
  STAGE_HERO: 'stage-hero',
  DRAWER: 'drawer',
  NOTIFICATIONS: 'notifications',
  SPORTS_MENU: 'sports-menu',
  MENU: 'menu',
  ATHLETE_HERO: 'athlete-hero',
  COMPETITION_HERO: 'competition-hero',
  COUNTRY_MEDALS: 'country-medals',
  CUSTOMER_FEEDBACK: 'customer-feedback',
  EXAMPLE_COMPONENT_ONE: 'example-component-one',
  EXAMPLE_COMPONENT_TWO: 'example-component-two',
  FAIR_PLAY_STANDINGS: 'fair-play-standings',
  TEAM_CALENDAR: 'team-calendar',
  TEAM_FIXTURES: 'team-fixtures',
  TEAM_LINEUP: 'team-lineup',
  TEAM_MATCH_REPORT: 'team-match-report',
  TEAM_MATCH_RESULT: 'team-match-result',
  TEAM_MATCH_STATISTICS: 'team-match-statistics',
  TEAM_PLAYER_STATS: 'team-player-stats',
  TEAM_STANDINGS: 'team-standings',
  ABBREVIATION_INFO: 'abbreviation-info',
  COMBINED_EVENTS_STANDINGS: 'combined-events-standings',
  EVENT_MAIN_MENU: 'event-main-menu',
  EVENT_HERO: 'event-hero',
  EVENT_MENU: 'event-menu',
  EVENT_PARTICIPANTS: 'event-participants',
  EVENT_STATUS: 'event-status',
  MEDAL_TABLE: 'medal-table',
  SCOPE_MENU: 'scope-menu',
  SCROLL_MENU: 'scroll-menu',
  SCOPE_RESULTS: 'scope-results',
  SEASON_STANDING: 'season-standings',
  SPORTEDIT_CALENDAR: 'sportedit-calendar',
  TOUR_STANDINGS: 'tour-standings',
  SPORT_CALENDAR: 'sport-calendar',
  TAB_MENU: 'tab-menu',
  DATE_SELECTOR: 'date-selector',
  TEAM_FIXTURES_MATCH: 'team-fixtures-match',
  TEAM_FIXTURES_TEAM: 'team-fixtures-team',
  MATCH_INFO: 'match-info',
  VIDEO_HERO: 'video-hero',
  RADIO_HERO: 'radio-hero',
  LIVE_FEED: 'live-feed',
  EVENT_INFO: 'event-info',
  MATCH_TIMER: 'match-timer',
  TRANSFERS: 'transfers',
};

export const DATA_PROVIDERS = {
  DATE_SELECTOR: 'dateSelector',
  NIFS_COUNTRIES: 'nifsCountries',
  NIFS_DISTRICTS: 'nifsDistricts',
  NIFS_MATCHES: 'nifsMatches',
  NIFS_MATCHES_BY_DATE: 'nifsMatchesByDate',
  NIFS_MATCH_SELECTOR: 'nifsMatchSelector',
  NIFS_SEARCH: 'nifsSearch',
  NIFS_STAGES: 'nifsStages',
  NIFS_TOURNAMENTS: 'nifsTournaments',
  NIFS_TEAM: 'nifsTeams',
  NEO_SPORTS: 'neoSports',
  NEO_COMPETITIONS: 'neoCompetitions',
  NEO_EVENTS: 'neoEvents',
  NEO_MEDAL_TABLES: 'neoMedalTables',
  NEO_SEARCH: 'neoSearch',
  NEO_SEASONS: 'neoSeasons',
  NEO_SEASON_EVENTS: 'neoSeasonEvents',
  SPORT_GROUP: 'sportGroup',
  CHANNEL_IDS: 'channelIds',
};

export const DATA_PICKER_GROUPS = {
  DATE_GROUP: 'dateGroup',
  NIFS_MATCH_GROUP: 'nifsMatchGroup',
  NIFS_STAGE_GROUP: 'nifsStageGroup',
  NIFS_DISTRICT_GROUP: 'nifsDistrictGroup',
  NIFS_TEAM_GROUP: 'nifsTeamGroup',
  NEO_COMPETITION_GROUP: 'neoCompetitionGroup',
  NEO_EVENT_GROUP: 'neoEventGroup',
  NEO_MEDAL_TABLE_GROUP: 'neoMedalTableGroup',
};

// export enum MATCH_STATUS_STRING {
//   PLAYED = 'Slutt',
//   NOT_STARTED = 'Ikke startet',
//   POSTPONED = 'Utsatt',
//   ABANDONED = 'Avbrutt',
//   WILL_NOT_BE_PLAYED = 'Vil ikke bli spilt',
//   ONGOING = 'Pågår',
//   FIRST_HALF = '1. omg',
//   HALF_TIME = 'Pause',
//   SECOND_HALF = '2.omg',
//   FIRST_HALF_EXTRA_TIME = '1. ekstraomgang',
//   SECOND_HALF_EXTRA_TIME = '2. ekstraomgang',
//   PENALTY_SHOOTOUT = 'Straffesparkkonkurranse',
//   PAUSE_BEFORE_EXTRA_TIME = 'Pause før ekstraomganger',
//   HALF_TIME_EXTRA_TIME = 'Pause ekstraomganger',
//   CANCELLED = 'Kansellert',
//   SECOND_HALF_TIME_EXTRA_TIME = '',
//   THIRD_HALF_EXTRA_TIME = '',
//   THIRD_HALF_TIME_EXTRA_TIME = '',
//   FOURTH_HALF_EXTRA_TIME = '',
//   THIRD_HALF = '',
//   FOURTH_HALF_TIME_EXTRA_TIME = '',
//   FIFTH_HALF_EXTRA_TIME = '',
//   FIFTH_HALF_TIME_EXTRA_TIME = '',
//   SIXTH_HALF_EXTRA_TIME = '',
//   SIXTH_HALF_TIME_EXTRA_TIME = '',
//   SEVENTH_HALF_EXTRA_TIME = '',
//   SEVENTH_HALF_TIME_EXTRA_TIME = '',
//   EIGHTH_HALF_EXTRA_TIME = '',
//   SECOND_HALF_TIME = '',
//   TABLE_SIMULATOR_PLAYED = '',
// }

export const MEDIA_QUERY_SIZE = {
  MOBILE_S: '320px',
  MOBILE_M: '375px',
  MOBILE_L: '412px',
  MOBILE_XL: '540px',
  TABLET_S: '600px',
  TABLET: '720px',
  LAPTOP: '1024px',
  LAPTOP_M: '1280px',
  LAPTOP_L: '1440px',
  DESKTOP: '1620px',
  DESKTOP_L: '1980px',
};

export const NIFS_DISTRICTS_ID_NAME_MAP: { [key: number]: string } = {
  1: 'Norges Fotballforbund',
  2: 'Østfold Fotballkrets',
  3: 'Akershus Fotballkrets',
  4: 'Oslo Fotballkrets',
  5: 'Indre Østland Fotballkrets',
  6: 'Buskerud Fotballkrets',
  7: 'Vestfold Fotballkrets',
  8: 'Telemark Fotballkrets',
  9: 'Rogaland Fotballkrets',
  10: 'Hordaland Fotballkrets',
  11: 'Sogn og Fjordane Fotballkrets',
  12: 'Nordmøre og Romsdal Fotballkrets',
  13: 'Trøndelag Fotballkrets',
  14: 'Nordland Fotballkrets',
  15: 'Troms Fotballkrets',
  16: 'Finnmark Fotballkrets',
  17: 'Sunnmøre Fotballkrets',
  18: 'Hålogaland Fotballkrets',
  19: 'Agder Fotballkrets',
};

export const FAIR_PLAY_SORTING_RULES = [
  { id: 1, norwegianText: 'Poengsnitt' },
  /*   { id: 2, norwegianText: 'Poeng'},
  { id: 2, norwegianText: 'Røde kort'},
  { id: 2, norwegianText: 'Gule kort'}, */
];

export const MEDIA_QUERY = {
  MOBILE_S: `screen and (max-width: ${MEDIA_QUERY_SIZE.MOBILE_S})`,
  MOBILE_M: `(min-width: ${MEDIA_QUERY_SIZE.MOBILE_M})`,
  MOBILE_L: `(min-width: ${MEDIA_QUERY_SIZE.MOBILE_L})`,
  MOBILE_ONLY: `(max-width: ${MEDIA_QUERY_SIZE.TABLET_S})`,
  TABLET_S: `(min-width: ${MEDIA_QUERY_SIZE.TABLET_S})`,
  TABLET: `(min-width: ${MEDIA_QUERY_SIZE.TABLET})`,
  TABLET_ONLY: `only screen and (min-device-width: ${MEDIA_QUERY_SIZE.TABLET}) and (max-device-width: ${MEDIA_QUERY_SIZE.LAPTOP})`,
  TABLET_LANDSCAPE: `(device-width: ${MEDIA_QUERY_SIZE.LAPTOP}) and (device-height: ${MEDIA_QUERY_SIZE.TABLET}) and (orientation: landscape)`,
  LAPTOP: `(min-width: ${MEDIA_QUERY_SIZE.LAPTOP})`,
  LAPTOP_M: `screen and (min-width: ${MEDIA_QUERY_SIZE.LAPTOP_M})`,
  LAPTOP_L: `(min-width: ${MEDIA_QUERY_SIZE.LAPTOP_L})`,
  DESKTOP: `(min-width: ${MEDIA_QUERY_SIZE.DESKTOP})`,
  DESKTOP_L: `(min-width: ${MEDIA_QUERY_SIZE.DESKTOP_L})`,
};

export const DARK_MODE = '@media (prefers-color-scheme: dark)';
