import { useBem } from '@ntb-sport/hooks';
import { UUIDs } from 'neo-common-enums';
import './active-athlete.css';

import { FinalScopeResult } from './FinalScopeResult/FinalScopeResult';
import { ActiveAthleteParticipant } from './ActiveAthleteParticipant/ActiveAthleteParticipant';
import { Checkpoints } from './CheckPoints/CheckPoints';
import { StylePoints } from './StylePoints/StylePoints';
import {
  SporteditActiveAthleteParticipant,
  SportEditActiveAthleteScopeResult,
  SporteditStylePoint,
} from '@ntb-sport/types';
import classNames from 'classnames';

export const CurrentActiveAthlete = ({
  eventParticipant,
  scopeResults,
  sportUUID,
}: {
  eventParticipant: SporteditActiveAthleteParticipant;
  scopeResults: { [key: string]: SportEditActiveAthleteScopeResult };
  sportUUID: string;
}) => {
  const bem = useBem('current-active-athlete');

  const activeScopeResults =
    scopeResults &&
    Object.values(scopeResults).filter(
      (scopeResult) => scopeResult?.scopeTypeId !== 2,
    );
  const stylePoints =
    scopeResults && Object.values(scopeResults)?.[0]?.stylePoints;

  const isScopeResultsAboveThree =
    activeScopeResults && Boolean(Number(activeScopeResults?.length) > 3);

  const isExtendedScopeResults = isScopeResultsAboveThree ? 'extended' : '';

  return (
    <div className={classNames(bem('', isExtendedScopeResults))}>
      <ActiveAthleteParticipant
        eventParticipant={eventParticipant}
        isCurrentEventParticipant={true}
      />

      <div className={bem('results')}>
        <FinalScopeResult scopeResults={scopeResults} sportUUID={sportUUID} />
        {sportUUID === UUIDs.Sport.ALPINE && (
          <Checkpoints
            eventParticipant={eventParticipant}
            scopeResults={activeScopeResults}
            isScopeResultsAboveThree={isScopeResultsAboveThree}
          />
        )}
        {sportUUID === UUIDs.Sport.SKI_JUMPING && (
          <StylePoints stylePoints={stylePoints as SporteditStylePoint[]} />
        )}
      </div>
    </div>
  );
};
