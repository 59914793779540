import { ArrowIcon, CircleIcon } from '../../icons';
import styled, { css } from 'styled-components';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  textAlign?: string;
  $isSorted?: boolean;
  divider?: boolean;
  collapse?: string;
  $displayDividerTop?: boolean;
  $displayDividerBottom?: boolean;
  highlight?: boolean;
  justifyContent?: string;
  $rowTypeId?: number;
  result?: any;
  highlightedTeam?: any;
}

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Row = styled.tr<StyledProps>`
  background-color: ${(props: any) =>
    props?.$highlightedTeam
      ? 'var(--highlighted-team-background-color)'
      : 'var(--row-background-color, transparent)'};

  box-shadow:
    inset 0px 1px 0px var(--ntb-row-background-color, transparent),
    inset 0px -1px 0px var(--ntb-row-background-color, transparent);

  ${(props: any) =>
    props?.$highlightedTeam
      ? css`
          box-shadow:
            inset 0px 1px 0px var(--highlighted-team-border-color, transparent),
            inset 0px -1px 0px var(--highlighted-team-border-color, transparent);
        `
      : props?.$displayDividerTop
        ? css`
            box-shadow: inset 0px 2px 0px
              var(--divider-line-bottom-color, transparent);
          `
        : props?.$displayDividerBottom
          ? css`
              box-shadow: inset 0px -2px 0px var(--divider-line-top-color, transparent);
            `
          : css`
              box-shadow:
                inset 0px 1px 0px var(--row-border-color, transparent),
                inset 0px -1px 0px var(--row-border-color, transparent);
            `}

  &:first-child {
    box-shadow: none;
  }

  &:last-child {
    box-shadow: none;
  }

  &:hover {
    background-color: var(--colors-table-cell-background__hover);
    color: var(--colors-table-cell-text__hover);
  }
`;

export const DividerRow = styled.tr`
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
`;

export const DividerCell = styled.td<StyledProps>`
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);
  padding: var(--ntb-space-1) var(--ntb-space-2)
    ${(props) =>
      props?.$matches?.tablet &&
      css`
        font-size: var(--ntb-fontSizes-sm);
        padding: var(--ntb-space-2);
      `};

  ${(props) =>
    props?.$matches?.desktopS &&
    css`
      font-size: var(--ntb-fontSizes-md);
    `};
`;

export const HeaderCell = styled.th<StyledProps>`
  text-align: ${(props) => props.textAlign || 'left'};
  padding: var(--ntb-space-1) var(--ntb-space-2);
  border-left: ${(props) =>
    props.divider && `1px solid var(--ntb-column-border-color)`};

  background-color: ${(props) => props.$isSorted && 'rgba(0,0,0,0.05)'};
  color: var(--colors-table-head-text);
  font-size: var(--header-cell-font-size-mobile);
  font-weight: var(--ntb-fontWeights-semibold);

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      padding: var(--ntb-space-1) var(--ntb-space-2);
    `};

  ${(props) =>
    props?.$matches?.desktop &&
    css`
      font-size: var(--header-cell-font-size-desktop);
      padding: var(--ntb-space-2) var(--ntb-space-3);
    `};

  ${(props) =>
    props.collapse === 'all' &&
    css`
      width: 1%;
      white-space: nowrap;
    `};

  ${(props) =>
    props.collapse === 'left' &&
    css`
      width: 1%;
      white-space: nowrap;
      padding-left: 0;
    `};
`;

export const Cell = styled.td<StyledProps>`
  text-align: ${(props) => props.textAlign || 'left'};
  padding: var(--ntb-space-1) var(--ntb-space-2);
  border-left-width: ${(props) => props.divider && `1px`};
  border-left-style: ${(props) => props.divider && `solid`};
  width: ${(props) => props.width || 'auto'};
  background-color: ${(props) => props.$isSorted && 'rgba(0,0,0,0.05)'};

  font-size: var(--cell-font-size-mobile);

  border-left-color: ${(props: any) =>
    props?.$highlightedTeam && props?.divider
      ? 'var(--highlighted-team-border-color, transparent)'
      : props?.divider
        ? 'var(--column-border-color, transparent)'
        : 'transparent'};

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      font-size: var(--cell-font-size-mobile);
    `};

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      font-size: var(--cell-font-size-tablet);
      padding: var(--ntb-space-1) var(--ntb-space-2);
    `};

  ${(props) =>
    props?.$matches?.desktop &&
    css`
      padding: var(--ntb-space-2) var(--ntb-space-3);
      font-size: var(--cell-font-size-desktop);
    `};

  ${(props) =>
    props.collapse === 'all' &&
    css`
      width: 1%;
      white-space: nowrap;
    `};

  ${(props) =>
    props.collapse === 'left' &&
    css`
      width: 1%;
      white-space: nowrap;
      padding-left: 0;
    `};

  font-weight: ${(props) =>
    props.highlight
      ? 'var(--ntb-fontWeights-semibold)'
      : 'var(--ntb-fontWeights-normal)'};

  &:first-child {
    border-left: none;
  }
`;

export const FlexWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'center'};
`;

interface PlacementProps extends StyledProps {
  $rowTypeId?: number;
}

export const Placement = styled.div<PlacementProps>`
  padding: var(--ntb-space-1) 0 var(--ntb-space-1) var(--ntb-space-2);
  border-left-style: solid;
  border-left-width: ${(props) => (props?.$matches?.tablet ? '4px' : '3px')};
  border-left-color: transparent;

  ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 1 &&
    css`
      border-left-color: var(--promotion-color);
    `}

  ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 2 &&
    css`
      border-left-color: var(--relegation-color);
    `}

    ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 3 &&
    css`
      border-left-color: var(--playoff-promotion-color);
    `}

    ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 4 &&
    css`
      border-left-color: var(--playoff-relegation-color);
    `}

  ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 5 &&
    css`
      border-left-color: var(--international-cup-qualifier-color-1);
    `}

    ${(props) =>
    props.$rowTypeId &&
    props.$rowTypeId === 6 &&
    css`
      border-left-color: var(--international-cup-qualifier-color-2);
    `}
`;

export const Club = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  ${(props) =>
    props?.$matches?.tablet &&
    css`
      column-gap: var(--ntb-space-3);
    `};
`;

export const ClubLogo = styled.img<StyledProps>`
  max-width: 20px;
  max-height: 20px;
  margin: auto;

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      max-width: 28px;
      max-height: 28px;
    `};
`;

export const ClubLogoWrapper = styled.div<StyledProps>`
  width: 20px;
  height: 20px;

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      width: 28px;
      height: 28px;
    `};
`;

export const ClubName = styled.div`
  font-weight: var(--ntb-fontWeights-semibold);

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SortArrows = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: var(--ntb-space-1);
`;

export const SortArrowUp = styled(ArrowIcon)`
  fill: var(--font-color);
  width: 8px;
  height: 8px;
  transform: rotate(-180deg);
`;

export const SortArrowDown = styled(ArrowIcon)`
  fill: var(--font-color);
  width: 8px;
  height: 8px;
  transform: rotate(0deg);
`;

export const FormIcon = styled(CircleIcon)<StyledProps>`
  display: inline;
  fill: ${(props) =>
    props.result === 1
      ? 'var(--win-color)'
      : props.result === 2
        ? 'var(--loss-color)'
        : 'var(--draw-color)'};
`;
