import { NifsMatch } from '@ntb-sport/types';

import { GroupFunctionParams } from './groupMatchesByDate';

interface MatchGroupRound {
  matchTypeId: number | string;
  round: number;
  key: number;
  matches: NifsMatch[];
}

interface MatchGroupRoundMap {
  [key: string]: MatchGroupRound;
}

export const groupMatchesByRound = ({
  matches,
  sortDirection = 'ascending',
}: GroupFunctionParams) => {
  const groups = matches.reduce(
    (groups: MatchGroupRoundMap, match: NifsMatch) => {
      if (match?.round) {
        const round = match.round;

        if (!groups[round]) {
          groups[round] = {
            matchTypeId: match?.matchTypeId || `round${match.round}`,
            round,
            key: round,
            matches: [],
          };
        }

        groups[round].matches.push(match);
      }

      return groups;
    },
    {},
  );

  const sortedGroups = Object.values(groups).sort((a, b) => {
    if (sortDirection === 'ascending') {
      return a.round > b.round ? -1 : 0;
    }
    if (sortDirection === 'descending') {
      return a.round < b.round ? -1 : 0;
    }

    return 0;
  });

  return sortedGroups;
};
