import { useEffect, useState } from 'react';
import { convertMS, displayClock } from '../utils';
import { differenceInMilliseconds } from 'date-fns';
import { MATCH_STATUS_ID } from '@ntb-sport/constants';

interface useMatchTimerProps {
  timeStart: string | Date;
  matchStatusId: MATCH_STATUS_ID;
}

export const useMatchTimer = ({
  timeStart,
  matchStatusId,
}: useMatchTimerProps) => {
  const [diffMS, setDiffMS] = useState(
    differenceInMilliseconds(new Date(), new Date(timeStart)),
  );

  useEffect(() => {
    if (displayClock(matchStatusId)) {
      const interval = setInterval(() => {
        setDiffMS(differenceInMilliseconds(new Date(), new Date(timeStart)));
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [matchStatusId, timeStart]);

  const { minutes, seconds } = convertMS(diffMS, matchStatusId);

  return {
    minutes,
    seconds,
  };
};
