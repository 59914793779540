import { MATCH_STATUS_ID } from '@ntb-sport/constants';

export const getIsOvertime = (
  matchStatusId: MATCH_STATUS_ID,
  minutes: number,
) => {
  if (
    (matchStatusId === MATCH_STATUS_ID.FIRST_HALF && minutes >= 45) ||
    (matchStatusId === MATCH_STATUS_ID.SECOND_HALF && minutes >= 90) ||
    (matchStatusId === MATCH_STATUS_ID.FIRST_HALF_EXTRA_TIME &&
      minutes >= 105) ||
    (matchStatusId === MATCH_STATUS_ID.SECOND_HALF_EXTRA_TIME && minutes >= 120)
  ) {
    return true;
  } else return false;
};

export const addMinutes = (matchStatusId: MATCH_STATUS_ID) => {
  if (Number(matchStatusId) === MATCH_STATUS_ID.FIRST_HALF) return 0;
  else if (Number(matchStatusId) === MATCH_STATUS_ID.SECOND_HALF) return 45;
  else if (Number(matchStatusId) === MATCH_STATUS_ID.FIRST_HALF_EXTRA_TIME)
    return 90;
  else if (Number(matchStatusId) === MATCH_STATUS_ID.SECOND_HALF_EXTRA_TIME)
    return 105;
  else return 0;
};

export const convertMS = (
  milliseconds: number,
  matchStatusId: MATCH_STATUS_ID,
) => {
  let hours, minutes, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = (minutes % 60) + addMinutes(matchStatusId);
  hours = hours % 24;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const displayClock = (matchStatusId: MATCH_STATUS_ID) => {
  switch (matchStatusId) {
    case MATCH_STATUS_ID.ONGOING:
    case MATCH_STATUS_ID.FIRST_HALF:
    case MATCH_STATUS_ID.SECOND_HALF:
    case MATCH_STATUS_ID.FIRST_HALF_EXTRA_TIME:
    case MATCH_STATUS_ID.SECOND_HALF_EXTRA_TIME:
    case MATCH_STATUS_ID.PENALTY_SHOOTOUT:
    case MATCH_STATUS_ID.THIRD_HALF:
    case MATCH_STATUS_ID.THIRD_HALF_EXTRA_TIME:
      return true;

    default:
      return false;
  }
};
