import * as S from './TeamMatchReport.styled';

import {
  COMPONENT_IDS,
  CUSTOMER_CONCERN_IDS,
  MATCH_EVENTS,
  MATCH_STATUS,
} from '@ntb-sport/constants';
import { useContext, useEffect, useRef, useState } from 'react';

import Events from './Events/Events';

import { isMatchInProgress } from '@ntb-sport/helpers';
import { useOutsideClick, useStateWithDep } from '@ntb-sport/hooks';

import { Host } from './Host/Host';
import { Summary } from './Summary/Summary';
import { NifsCustomerContext } from '@ntb-sport/customer';
import { NifsCustomerSettings } from '@ntb-sport/types';

export const TeamMatchReport = ({
  matchEvents,
  matchStatusId,
  matchHighlightsVideos,
  host,
  personLink,
  teamLink,
  VideoPlayer,
  customerConcernId,
  isLoading,
  tournamentId,
  stageId,
  isAllowedToShowLiveReport,
}: {
  matchEvents: any;
  matchStatusId: number;
  matchHighlightsVideos: any;
  host: any;
  personLink?: any;
  teamLink?: any;
  VideoPlayer?: any;
  customerConcernId: any;
  isLoading: boolean;
  tournamentId: number;
  stageId: number;
  isAllowedToShowLiveReport: boolean;
}) => {
  const [highlightsOnly, setHighlightsOnly] = useStateWithDep(
    matchStatusId === MATCH_STATUS.PLAYED ? true : false,
  );
  const [highlightedEvents, setHighlightedEvents] = useState<any>();
  const [mediaplayerSrc, setMediaPlayerSrc] = useState();
  const mediaPlayerRef = useRef();
  useOutsideClick(mediaPlayerRef, () => setMediaPlayerSrc(undefined));

  const { nifsCustomer } = useContext<any>(NifsCustomerContext) as {
    nifsCustomer: NifsCustomerSettings;
  };

  useEffect(() => {
    if (matchEvents && matchStatusId === MATCH_STATUS.PLAYED) {
      const matchEndIndex = matchEvents.findIndex(
        (event: any) => event.matchEventTypeId === MATCH_EVENTS.MATCH_ENDS,
      );
      const matchSummary =
        matchEvents.find(
          (event: any) => event.matchEventTypeId === MATCH_EVENTS.SUMMARY,
        ) || matchEvents[matchEndIndex + 1];
      const matchHighlightVideo =
        matchHighlightsVideos &&
        matchHighlightsVideos.find(
          (video: any) => video.customerConcernId === customerConcernId,
        );
      setHighlightedEvents({
        summary: matchSummary,
        matchHighlightVideo,
      });
    } else {
      setHighlightedEvents({});
    }
  }, [matchEvents, matchStatusId, matchHighlightsVideos]);

  const onToggleHighlightsOnly = () => {
    setHighlightsOnly(!highlightsOnly);
  };

  const onClickHighlightVideo = ({ src }: { src: any }) => {
    setMediaPlayerSrc(src);
  };

  const onCloseHighlightVideo = () => {
    setMediaPlayerSrc(undefined);
  };

  const isLive =
    isMatchInProgress(matchStatusId) ||
    (matchStatusId === MATCH_STATUS.NOT_STARTED &&
      matchEvents &&
      matchEvents.length > 0);

  const filteredMatchEvents = highlightsOnly
    ? matchEvents?.filter(
        (event: any) =>
          event.important ||
          event.matchEventTypeId === MATCH_EVENTS.PLAYER_SUBSTITUTED ||
          event.matchEventTypeId === MATCH_EVENTS.SUBSTITUTE_ON,
      )
    : matchEvents;

  const sortedMatchEvents =
    isLive && filteredMatchEvents
      ? filteredMatchEvents.sort((a: any, b: any) => {
          if (a.periodId === b.periodId) {
            if (a.time === b.time) {
              if (
                a?.overtimeRounded === b?.overtimeRounded ||
                a.overtime === b.overtime
              ) {
                return a.sorting < b.sorting ? 1 : -1;
              }

              return a.overtimeRounded < b.overtimeRounded ||
                a.overtime < b.overtime
                ? 1
                : -1;
            }

            return a?.timeRounded < b?.timeRounded || a.time < b.time ? 1 : -1;
          }
          return a.periodId < b.periodId ? 1 : -1;
        })
      : filteredMatchEvents;

  const showComments =
    (nifsCustomer?.includeStageIds?.includes(stageId) ||
      nifsCustomer?.includeStageIds === null) &&
    !nifsCustomer?.excludeStageIds?.includes(stageId);

  return (
    <S.QueryContainer
      data-component-id={COMPONENT_IDS.TEAM_MATCH_REPORT}
      data-tournament-id={tournamentId}
    >
      {mediaplayerSrc &&
        customerConcernId === CUSTOMER_CONCERN_IDS.NRK &&
        VideoPlayer({
          src: mediaplayerSrc,
          isRadio: false,
          onClose: onCloseHighlightVideo,
          autoplay: true,
        })}
      {(highlightedEvents?.summary?.comment ||
        highlightedEvents?.matchHighlightVideo) &&
        showComments &&
        isAllowedToShowLiveReport && (
          <Summary
            highlightedEvents={highlightedEvents}
            onClick={onClickHighlightVideo}
          />
        )}
      {showComments && isAllowedToShowLiveReport && (
        <Host
          host={host}
          onChange={onToggleHighlightsOnly}
          isChecked={highlightsOnly}
        />
      )}

      <Events
        events={sortedMatchEvents}
        matchStatusId={matchStatusId}
        personLink={personLink}
        teamLink={teamLink}
        onClickHighlightVideo={onClickHighlightVideo}
        customerConcernId={customerConcernId}
        showComments={showComments && isAllowedToShowLiveReport}
      />
    </S.QueryContainer>
  );
};

export default TeamMatchReport;
