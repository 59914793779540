import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

interface StyledProps {
  $isOvertime?: boolean;
}

export const MatchStatus = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-style: solid;
  border-width: 2px;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  border-radius: 4px;
  border-color: var(--border-color);

  font-size: var(--ntb-fontSizes-xs);
`;

export const MatchTimer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: var(--background-color);
  border-style: solid;
  border-width: 2px;
  border-color: var(--border-color);
  border-radius: 50%;
  color: var(--font-color);
  margin: 0 var(--ntb-space-3);

  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    width: 48px;
    height: 48px;
  }
`;
