import { MATCH_STATUS, SPORT_GROUP } from '@ntb-sport/constants';
import {
  NifsCustomerStage,
  NifsLiveFeed,
  NifsMatch,
  NifsMatchRelation,
  NifsMatchResult,
  NifsStage,
  NifsTournament,
} from '@ntb-sport/types';

export const getIsNifsSportGroup = (sportGroup: string | string[]) => {
  switch (sportGroup) {
    case SPORT_GROUP.FOOTBALL:
    case SPORT_GROUP.ICE_HOCKEY:
    case SPORT_GROUP.HANDBALL:
    case SPORT_GROUP.BANDY:
      return true;

    default:
      return false;
  }
};

export const isMatchInProgress = (matchStatusId: number): boolean => {
  switch (matchStatusId) {
    case MATCH_STATUS.ONGOING:
    case MATCH_STATUS.FIRST_HALF:
    case MATCH_STATUS.HALF_TIME:
    case MATCH_STATUS.SECOND_HALF:
    case MATCH_STATUS.SECOND_HALF_TIME:
    case MATCH_STATUS.PAUSE_BEFORE_EXTRA_TIME:
    case MATCH_STATUS.FIRST_HALF_EXTRA_TIME:
    case MATCH_STATUS.SECOND_HALF_TIME_EXTRA_TIME:
    case MATCH_STATUS.SECOND_HALF_EXTRA_TIME:
    case MATCH_STATUS.PENALTY_SHOOTOUT:
    case MATCH_STATUS.THIRD_HALF:
    case MATCH_STATUS.THIRD_HALF_EXTRA_TIME:
    case MATCH_STATUS.THIRD_HALF_TIME_EXTRA_TIME:
      return true;

    default:
      return false;
  }
};

export const filterMatchesNotPlayed = (fixtures: NifsMatch[]): NifsMatch[] => {
  return fixtures.filter((fixture) => {
    const fixtureDate = new Date(fixture?.timestamp).getTime();
    const currentDate = new Date().getTime();

    if (
      fixture?.matchStatusId !== MATCH_STATUS.PLAYED &&
      fixture?.matchStatusId !== MATCH_STATUS.CANCELLED &&
      fixture?.matchStatusId !== MATCH_STATUS.WILL_NOT_BE_PLAYED
    ) {
      return true;
    }
    if (
      (fixture?.matchStatusId === MATCH_STATUS.WILL_NOT_BE_PLAYED ||
        fixture?.matchStatusId === MATCH_STATUS.CANCELLED) &&
      fixtureDate > currentDate
    ) {
      return true;
    }
    return false;
  });
};

export const filterMatchesPlayed = (fixtures: NifsMatch[]): NifsMatch[] => {
  return fixtures.filter((fixture) => {
    const fixtureDate = new Date(fixture?.timestamp).getTime();
    const currentDate = new Date().getTime();

    if (fixture?.matchStatusId === MATCH_STATUS.PLAYED) {
      return true;
    }
    if (
      (fixture?.matchStatusId === MATCH_STATUS.WILL_NOT_BE_PLAYED ||
        fixture?.matchStatusId === MATCH_STATUS.CANCELLED) &&
      fixtureDate < currentDate
    ) {
      return true;
    }
    return false;
  });
};

interface TournamentStageMatchMap {
  [key: number]: {
    priority: number;
    stages: {
      [key: number]: {
        priority: number;
        matches: {
          [key: number]: NifsMatch;
        };
      } & NifsStage;
    };
  } & NifsTournament;
}

export const getStagesWithMatches = (
  matches: NifsMatch[] | undefined,
  customerStages: NifsCustomerStage[] | undefined,
): TournamentStageMatchMap => {
  if (!matches || !customerStages) return [];

  const stagesWithMatches = matches.reduce(
    (accumulator: TournamentStageMatchMap, match: NifsMatch) => {
      const tournamentId = match?.stage?.tournament?.id;
      const stageId = match?.stage?.id;
      const matchId = match?.id;

      const customerTournamentId = customerStages.find(
        (cs: NifsCustomerStage) => cs?.stage?.tournament?.id === tournamentId,
      )?.stage?.tournament?.id;

      const customerStage = customerStages.find(
        (cs: NifsCustomerStage) => cs?.stage?.id === stageId,
      );

      const customerStageId = customerStage?.stage?.id;
      const customerStagePriority = customerStage?.priority || 999999; // Default to lowest priority

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId]
      ) {
        accumulator[customerTournamentId] = {
          ...match.stage.tournament,
          priority: customerStagePriority,
          stages: {},
        };
      } else if (
        customerTournamentId &&
        customerStagePriority &&
        accumulator[customerTournamentId] &&
        customerStagePriority < accumulator[customerTournamentId].priority
      ) {
        accumulator[customerTournamentId].priority = customerStagePriority;
      }

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId].stages[customerStageId]
      ) {
        accumulator[customerTournamentId].stages[customerStageId] = {
          ...match.stage,
          priority: customerStagePriority,
          matches: {},
        };
      }

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId].stages[customerStageId].matches[
          matchId
        ]
      ) {
        accumulator[customerTournamentId].stages[customerStageId].matches[
          matchId
        ] = {
          ...match,
        };
      }

      return accumulator;
    },
    {},
  );

  return stagesWithMatches;
};

export const isMatchNotStarted = (matchStatusId: number): boolean => {
  switch (matchStatusId) {
    case MATCH_STATUS.NOT_STARTED:
    case MATCH_STATUS.POSTPONED:
    case MATCH_STATUS.WILL_NOT_BE_PLAYED:
    case MATCH_STATUS.CANCELLED:
      return true;

    default:
      return false;
  }
};

export function camelize(str: string): string {
  let arr = str.split('-');
  let capital = arr.map((item, index) =>
    index
      ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      : item.toLowerCase(),
  );
  let capitalString = capital.join('');

  return capitalString;
}

export const getTeamForm = ({
  teamId,
  winningTeamId,
}: {
  teamId: number;
  winningTeamId: number | null;
}) => {
  if (winningTeamId && teamId === winningTeamId) {
    return 'S';
  } else if (winningTeamId && teamId !== winningTeamId) {
    return 'T';
  } else {
    return 'U';
  }
};

interface GetWinningTeamProps {
  result: NifsMatchResult;
  homeTeamId: number;
  awayTeamId: number;
  matchRelation: NifsMatchRelation;
}

export const getWinningTeamId = ({
  result,
  homeTeamId,
  awayTeamId,
  matchRelation,
}: GetWinningTeamProps): number | null => {
  if (matchRelation?.winningTeamId === homeTeamId) {
    return homeTeamId;
  }
  if (matchRelation?.winningTeamId === awayTeamId) {
    return awayTeamId;
  }

  if (!result) {
    return null;
  }

  if (
    result.homeScorePenalties !== null &&
    result.homeScorePenalties !== undefined &&
    result.awayScorePenalties !== null &&
    result.awayScorePenalties !== undefined
  ) {
    if (result.homeScorePenalties === result.awayScorePenalties) {
      return null;
    }
    return result.homeScorePenalties > result.awayScorePenalties
      ? homeTeamId
      : awayTeamId;
  }

  if (
    result.homeScore120 !== null &&
    result.homeScore120 !== undefined &&
    result.awayScore120 !== null &&
    result.awayScore120 !== undefined
  ) {
    if (result.homeScore120 === result.awayScore120) {
      return null;
    }
    return result.homeScore120 > result.awayScore120 ? homeTeamId : awayTeamId;
  }

  if (
    result.homeScore90 !== null &&
    result.homeScore90 !== undefined &&
    result.awayScore90 !== null &&
    result.awayScore90 !== undefined
  ) {
    if (result.homeScore90 === result.awayScore90) {
      return null;
    }
    return result.homeScore90 > result.awayScore90 ? homeTeamId : awayTeamId;
  }

  return null;
};

export const getNextMatch = (
  matches: NifsMatch[],
  delay: number = 0,
): NifsMatch => {
  // Get the current timestamp
  const currentTimestamp = new Date();
  currentTimestamp.setMinutes(currentTimestamp.getMinutes() - delay);

  // Filter events that are in the future
  const upcomingMatches = matches.filter(
    (match) => new Date(match.timestamp) > currentTimestamp,
  );

  // Sort the upcoming events by timestamp
  upcomingMatches.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  );

  // The first element in the sorted array is the next upcoming event
  return upcomingMatches[0];
};

export const isAllowedCustomerOnLiveFeed = (
  livefeed: NifsLiveFeed,
  customerConcernId: number,
): boolean => {
  // All customers are allowed if we have not set specific customers in NIFS
  if (!livefeed?.allowedCustomers?.length) {
    return true;
  }
  return Boolean(
    livefeed.allowedCustomers.find(
      (allowedCustomer) =>
        allowedCustomer.customerConcernId === customerConcernId,
    ),
  );
};
