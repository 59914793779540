import { UUIDs } from 'neo-common-enums';
import './final-scope-result.css';
import '../active-athlete.css';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';
import { SportEditActiveAthleteScopeResult } from '@ntb-sport/types';

export const FinalScopeResult = ({
  scopeResults,
  sportUUID,
}: {
  scopeResults: { [key: string]: SportEditActiveAthleteScopeResult };
  sportUUID: string;
}) => {
  const isAlpine = sportUUID === UUIDs.Sport.ALPINE;
  const isSkiJumping = sportUUID === UUIDs.Sport.SKI_JUMPING;
  const bem = useBem('final-scope-result');

  const getColorScheme = (timeDifference: string) => {
    if (Math.sign(Number(timeDifference)) === 1) return 'red';
    if (Math.sign(Number(timeDifference)) === -1) return 'green';
    return '';
  };

  return Object.values(scopeResults)
    ?.filter((scopeResult) => scopeResult.scopeTypeId === 2)
    ?.map((scopeResult) => {
      const timeDifferance = getColorScheme(
        scopeResult.timeDifferenceToLeaderLive as string,
      );

      return (
        <div className={bem()} key={scopeResult.uuid}>
          <div className={bem('result')}>
            <p>{(isAlpine && 'Tid') || (isSkiJumping && 'Lengde')}</p>

            <div className={bem('tag')}>
              {isAlpine && scopeResult?.duration ? (
                <time>{scopeResult.duration}</time>
              ) : isSkiJumping && scopeResult.distance ? (
                `${scopeResult.distance}m`
              ) : (
                <div className="active-athlete-spinner">
                  <span />
                </div>
              )}
            </div>
          </div>

          <div className={bem('result')}>
            <p>Plassering</p>
            <div className={bem('tag')}>
              {scopeResult.rank ?? (
                <div className="active-athlete-spinner">
                  <span />
                </div>
              )}
            </div>
          </div>

          <div className={bem('result')}>
            <p>{(isAlpine && 'Mål') || (isSkiJumping && 'Poeng')}</p>
            {isAlpine && (
              <div className={classNames(bem('tag', timeDifferance))}>
                {scopeResult.timeDifferenceToLeaderLive ||
                  scopeResult.timeDifference || (
                    <div className="active-athlete-spinner">
                      <span />
                    </div>
                  )}
              </div>
            )}
            {isSkiJumping && (
              <div className={bem('tag')}>
                {scopeResult.points ? (
                  `${scopeResult.points} p`
                ) : (
                  <div className="active-athlete-spinner" />
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
};
