import {
  NifsCustomerStage,
  NifsMatchEventInNotifications,
} from '@ntb-sport/types';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchMatchEventsType {
  apiBaseUrl: string;
  customerStages: NifsCustomerStage[];
}

export const fetchMatchEvents = async ({
  apiBaseUrl,
  customerStages,
}: FetchMatchEventsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/matchEvents/?latest=1`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  const allowedStages = customerStages?.reduce(
    (acc: number[], customerStage: NifsCustomerStage) => {
      if (!acc.includes(customerStage.stage.id)) {
        acc.push(customerStage.stage.id);
      }
      return acc;
    },
    [],
  );

  return {
    count: 0,
    data: json?.filter(
      (matchEvent: NifsMatchEventInNotifications) =>
        matchEvent?.stageId && allowedStages?.includes(matchEvent.stageId),
    ),
  };
};
