import { useBem } from '@ntb-sport/hooks';
import { SporteditStylePoint } from '@ntb-sport/types';
import classNames from 'classnames';
import './style-points.css';
import '../active-athlete.css';
export const StylePoints = ({
  stylePoints,
}: {
  stylePoints: SporteditStylePoint[];
}) => {
  const bem = useBem('style-points');

  const highestJudgePoint =
    stylePoints &&
    Math.max(...stylePoints.map((stylePoint) => Number(stylePoint.points)));

  const lowestJudgePoint =
    stylePoints &&
    Math.min(...stylePoints.map((stylePoint) => Number(stylePoint.points)));

  const applicableStylePoints =
    stylePoints &&
    stylePoints.reduce((stylePoints: SporteditStylePoint[], stylePoint) => {
      if (
        Number(stylePoint?.points) === highestJudgePoint &&
        !stylePoints?.find((stylePoint) => stylePoint.highest)
      ) {
        stylePoint = {
          ...stylePoint,
          highest: true,
          strike: true,
        };
      } else if (
        Number(stylePoint?.points) === lowestJudgePoint &&
        !stylePoints?.find((stylePoint) => stylePoint?.lowest)
      ) {
        stylePoint = {
          ...stylePoint,
          lowest: true,
          strike: true,
        };
      }
      stylePoints.push(stylePoint);
      return stylePoints;
    }, []);

  return (
    <div className={bem()}>
      <p>Stilpoeng</p>
      <div className={bem('results')}>
        {applicableStylePoints ? (
          applicableStylePoints?.map((stylePoint) => {
            const colorScheme = (stylePoint.strike && 'red') || 'green';

            return (
              <div
                key={stylePoint.uuid}
                className={classNames(bem('tag', colorScheme))}
              >
                {stylePoint.points}
              </div>
            );
          })
        ) : (
          <div className="active-athlete-spinner">
            <span />
          </div>
        )}
      </div>
    </div>
  );
};
