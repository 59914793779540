export enum LineUpPositions {
  GOALKEEPER = 1,
  RIGHT_FULL_BACK = 3,
  RIGHT_CENTER_HALF = 27,
  CENTER_HALF = 5,
  LEFT_CENTER_HALF = 28,
  LEFT_FULL_BACK = 4,

  RIGHT_DEFENSIVE_MIDFIELD = 17,
  RIGHT_DEFENSIVE_CENTRAL_MIDFIELD = 29,
  DEFENSIVE_MIDFIELD = 18,
  LEFT_DEFENSIVE_CENTRAL_MIDFIELD = 30,
  LEFT_DEFENSIVE_MIDFIELD = 19,

  RIGHT_MIDFIELD = 7,
  INSIDE_RIGHT_MIDFIELD = 25,
  RIGHT_CENTRAL_MIDFIELD = 35,
  CENTER_MIDFIELD = 9,
  LEFT_CENTRAL_MIDFIELD = 36,

  INSIDE_LEFT_MIDFIELD = 26,
  LEFT_MIDFIELD = 8,
  RIGHT_OFFENSIVE_MIDFIELD = 22,
  RIGHT_OFFENSIVE_CENTRAL_MIDFIELD = 31,
  OFFENSIVE_MIDFIED = 23,

  LEFT_OFFENSIVE_CENTRAL_MIDFIED = 32,
  LEFT_OFFENSIVE_MIDFIED = 24,
  RIGHT_WING = 20,
  RIGHT_STRIKER = 33,
  STRIKER = 10,
  LEFT_STRIKER = 34,
  LEFT_WING = 21,
  FORWARD = 13,
}
