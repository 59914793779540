import { UUIDs } from 'neo-common-enums';

export const SPORT_ID = {
  FOOTBALL: 1,
  ICE_HOCKEY: 2,
  HANDBALL: 3,
  BANDY: 4,
  ALPINE: UUIDs.Sport.ALPINE,
  CROSS_COUNTRY_SKIING: UUIDs.Sport.CROSS_COUNTRY_SKIING,
  BIATHLON: UUIDs.Sport.BIATHLON,
  SKI_JUMPING: UUIDs.Sport.SKI_JUMPING,
  NORDIC_COMBINED: UUIDs.Sport.NORDIC_COMBINED,
  SPEED_SKATING: UUIDs.Sport.SPEED_SKATING,
  CURLING: UUIDs.Sport.CURLING,
  BOBSLEIGH: UUIDs.Sport.BOBSLEIGH,
  LUGE: UUIDs.Sport.LUGE,
  SKELETON: UUIDs.Sport.SKELETON,
  FREESTYLE_SKIING: UUIDs.Sport.FREESTYLE_SKIING,
  SNOWBOARDING: UUIDs.Sport.SNOWBOARDING,
  SHORT_TRACK_SPEED_SKATING: UUIDs.Sport.SHORT_TRACK_SPEED_SKATING,
  FIGURE_SKATING: UUIDs.Sport.FIGURE_SKATING,
  ATHLETICS: UUIDs.Sport.ATHLETICS,
  CYCLING: UUIDs.Sport.CYCLING,
  SUMMER_OLYMPICS: 'summer-olympics',
};

export const NORWEGIAN_SPORT_ID = {
  FOOTBALL: 1,
  ICE_HOCKEY: 2,
  HANDBALL: 3,
  BANDY: 4,
  ALPINE: 'alpint',
  CROSS_COUNTRY_SKIING: 'langrenn',
  BIATHLON: 'skiskyting',
  SKI_JUMPING: 'hopp',
  NORDIC_COMBINED: 'kombinert',
  SPEED_SKATING: 'skoyter',
  CURLING: UUIDs.Sport.CURLING,
  BOBSLEIGH: UUIDs.Sport.BOBSLEIGH,
  LUGE: UUIDs.Sport.LUGE,
  SKELETON: UUIDs.Sport.SKELETON,
  FREESTYLE_SKIING: UUIDs.Sport.FREESTYLE_SKIING,
  SNOWBOARDING: UUIDs.Sport.SNOWBOARDING,
  SHORT_TRACK_SPEED_SKATING: UUIDs.Sport.SHORT_TRACK_SPEED_SKATING,
  FIGURE_SKATING: UUIDs.Sport.FIGURE_SKATING,
  ATHLETICS: 'friidrett',
  CYCLING: 'sykling',
};

export const SPORT_ID_NORWEGIAN = {
  [SPORT_ID.FOOTBALL]: 'fotball',
  [SPORT_ID.ICE_HOCKEY]: 'ishockey',
  [SPORT_ID.HANDBALL]: 'handball',
  [SPORT_ID.BANDY]: 'bandy',
  [SPORT_ID.BIATHLON]: 'skiskyting',
  [SPORT_ID.CROSS_COUNTRY_SKIING]: 'langrenn',
  [SPORT_ID.ALPINE]: 'alpint',
  [SPORT_ID.SKI_JUMPING]: 'hopp',
  [SPORT_ID.NORDIC_COMBINED]: 'kombinert',
  [SPORT_ID.SPEED_SKATING]: 'skoyter',
  [SPORT_ID.CURLING]: 'curling',
  [SPORT_ID.BOBSLEIGH]: 'bob',
  [SPORT_ID.LUGE]: 'aking',
  [SPORT_ID.SKELETON]: 'skeleton',
  [SPORT_ID.FREESTYLE_SKIING]: 'friski',
  [SPORT_ID.SNOWBOARDING]: 'snowboard',
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: 'kortbane-skoyter',
  [SPORT_ID.FIGURE_SKATING]: 'kunstlop',
  [SPORT_ID.ATHLETICS]: 'friidrett',
  [SPORT_ID.CYCLING]: 'sykling',
  [SPORT_ID.SUMMER_OLYMPICS]: 'sommer-ol',
};

export const SPORT_ID_TO_NORWEGIAN_NAME = {
  [SPORT_ID.FOOTBALL]: 'Fotball',
  [SPORT_ID.ICE_HOCKEY]: 'Ishockey',
  [SPORT_ID.HANDBALL]: 'Håndball',
  [SPORT_ID.BANDY]: 'Bandy',
  [SPORT_ID.BIATHLON]: 'Skiskyting',
  [SPORT_ID.CROSS_COUNTRY_SKIING]: 'Langrenn',
  [SPORT_ID.ALPINE]: 'Alpint',
  [SPORT_ID.SKI_JUMPING]: 'Hopp',
  [SPORT_ID.NORDIC_COMBINED]: 'Kombinert',
  [SPORT_ID.SPEED_SKATING]: 'Skøyter',
  [SPORT_ID.CURLING]: 'Curling',
  [SPORT_ID.BOBSLEIGH]: 'Bob',
  [SPORT_ID.LUGE]: 'Aking',
  [SPORT_ID.SKELETON]: 'Skeleton',
  [SPORT_ID.FREESTYLE_SKIING]: 'Friski',
  [SPORT_ID.SNOWBOARDING]: 'Snowboard',
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: 'Kortbane-skøyter',
  [SPORT_ID.FIGURE_SKATING]: 'Kunstløp',
  [SPORT_ID.ATHLETICS]: 'Friidrett',
  [SPORT_ID.CYCLING]: 'Sykling',
  [SPORT_ID.SUMMER_OLYMPICS]: 'Sommer-OL',
};

export const SPORT_ID_NORWEGIAN_TO_UUID = {
  fotball: SPORT_ID.FOOTBALL,
  ishockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
  skiskyting: SPORT_ID.BIATHLON,
  langrenn: SPORT_ID.CROSS_COUNTRY_SKIING,
  alpint: SPORT_ID.ALPINE,
  hopp: SPORT_ID.SKI_JUMPING,
  kombinert: SPORT_ID.NORDIC_COMBINED,
  skoyter: SPORT_ID.SPEED_SKATING,
  curling: SPORT_ID.CURLING,
  bob: SPORT_ID.BOBSLEIGH,
  aking: SPORT_ID.LUGE,
  skeleton: SPORT_ID.SKELETON,
  friski: SPORT_ID.FREESTYLE_SKIING,
  snowboard: SPORT_ID.SNOWBOARDING,
  'kortbane-skoyter': SPORT_ID.SHORT_TRACK_SPEED_SKATING,
  kunstlop: SPORT_ID.FIGURE_SKATING,
  friidrett: SPORT_ID.ATHLETICS,
  sykling: SPORT_ID.CYCLING,
};

export const WINTERSPORTS = [
  SPORT_ID.BIATHLON,
  SPORT_ID.CROSS_COUNTRY_SKIING,
  SPORT_ID.ALPINE,
  SPORT_ID.SKI_JUMPING,
  SPORT_ID.NORDIC_COMBINED,
  SPORT_ID.SPEED_SKATING,
  SPORT_ID.CURLING,
  SPORT_ID.BOBSLEIGH,
  SPORT_ID.LUGE,
  SPORT_ID.SKELETON,
  SPORT_ID.FREESTYLE_SKIING,
  SPORT_ID.SNOWBOARDING,
  SPORT_ID.SHORT_TRACK_SPEED_SKATING,
  SPORT_ID.FIGURE_SKATING,
];

export const NIFS_SPORT_GROUP_NORWEGIAN_TO_SPORT_ID = {
  fotball: SPORT_ID.FOOTBALL,
  ishockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
};

export const NIFS_SPORT_GROUP_TO_SPORT_ID = {
  football: SPORT_ID.FOOTBALL,
  icehockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
};

export const NORWEGIAN_SPORT_GROUP = {
  FOOTBALL: 'fotball',
  ICE_HOCKEY: 'ishockey',
  HANDBALL: 'handball',
  BANDY: 'bandy',
  WINTERSPORTS: 'vintersport',
  ATHLETICS: 'friidrett',
  CYCLING: 'sykling',
};

export const SPORT_GROUP = {
  FOOTBALL: 'football',
  ICE_HOCKEY: 'icehockey',
  HANDBALL: 'handball',
  BANDY: 'bandy',
  WINTERSPORTS: 'wintersports',
  ATHLETICS: 'athletics',
  CYCLING: 'cycling',
  SUMMER_OLYMPICS: 'summer-olympics',
};

export const NORWEGIAN_SPORT_GROUP_TO_ENGLISH = {
  fotball: 'football',
  ishockey: 'icehockey',
  handball: 'handball',
  bandy: 'bandy',
  vintersport: 'wintersports',
  friidrett: 'athletics',
  sykling: 'cycling',
  ['sommer-ol']: 'summer-olympics',
};

export const ENGLISH_SPORT_GROUP_TO_NORWEGIAN = {
  football: 'fotball',
  icehockey: 'ishockey',
  handball: 'handball',
  bandy: 'bandy',
  wintersports: 'vintersport',
  athletics: 'friidrett',
  cycling: 'sykling',
  ['summer-olympics']: 'sommer-ol',
};

export const NORWEGIAN_SPORT_ID_TO_ENGLISH: { [key: string]: string } = {
  fotball: 'football',
  ishockey: 'icehockey',
  handball: 'handball',
  bandy: 'bandy',
  alpint: 'sport-alpine',
  langrenn: 'sport-cross-country-skiing',
  skiskyting: 'sport-biathlon',
  skoyter: 'sport-speed-skating',
  hopp: 'sport-ski-jumping',
  kombinert: 'sport-nordic-combined',
  friidrett: 'sport-athletics',
  sykling: 'sport-cycling',
};

export const ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME = {
  football: 'fotball',
  icehockey: 'ishockey',
  handball: 'håndball',
  bandy: 'bandy',
  wintersports: 'vintersport',
  athletics: 'friidrett',
  cycling: 'sykling',
  ['summer-olympics']: 'sommer-ol',
};

export const NORWEGIAN_SPORT_GROUP_TO_NIFS_SPORT_ID = {
  fotball: 1,
  ishockey: 2,
  handball: 3,
  bandy: 4,
};

export const SPORT_GROUP_TO_NIFS_SPORT_ID = {
  football: 1,
  icehockey: 2,
  handball: 3,
  bandy: 4,
};

export const DEFAULT_SPORT_ID_BY_SPORT_GROUP = {
  [SPORT_GROUP.FOOTBALL]: 1,
  [SPORT_GROUP.ICE_HOCKEY]: 2,
  [SPORT_GROUP.HANDBALL]: 3,
  [SPORT_GROUP.BANDY]: 4,
  [SPORT_GROUP.WINTERSPORTS]: 'langrenn',
  [SPORT_GROUP.ATHLETICS]: 'friidrett',
  [SPORT_GROUP.CYCLING]: 'sykling',
  [SPORT_GROUP.SUMMER_OLYMPICS]: 'summer-olympics',
};

export const SPORT_ID_BELONGS_TO_SPORT_GROUP = {
  [SPORT_ID.FOOTBALL]: SPORT_GROUP.FOOTBALL,
  [SPORT_ID.ICE_HOCKEY]: SPORT_GROUP.ICE_HOCKEY,
  [SPORT_ID.HANDBALL]: SPORT_GROUP.HANDBALL,
  [SPORT_ID.BANDY]: SPORT_GROUP.BANDY,
  [SPORT_ID.ALPINE]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.CROSS_COUNTRY_SKIING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.BIATHLON]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SKI_JUMPING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.NORDIC_COMBINED]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SPEED_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.CURLING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.BOBSLEIGH]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.LUGE]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SKELETON]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.FREESTYLE_SKIING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SNOWBOARDING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.FIGURE_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.ATHLETICS]: SPORT_GROUP.ATHLETICS,
  [SPORT_ID.CYCLING]: SPORT_GROUP.CYCLING,
};
