import { LabelProperties } from 'neo-common-enums';

export const RESULT_IDS = {
  [LabelProperties.ResultId.DID_NOT_START]: {
    name: 'DNS',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_FIRST_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_SECOND_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  204: {
    name: 'Veltet',
    type: 'error',
  },

  [LabelProperties.ResultId.DISQUALIFIED]: { name: 'DSQ', type: 'error' },
  [LabelProperties.ResultId.LAPPED]: { name: 'LPD', type: 'error' },
  [LabelProperties.ResultId.NOT_PERMITTED_TO_START]: {
    name: 'NPS',
    type: 'error',
  },
  [LabelProperties.ResultId.NO_RESULT]: {
    name: 'X',
    type: 'error',
  },
  60: {
    name: 'X',
    type: 'normal',
  },
  61: {
    name: 'O',
    type: 'normal',
  },
  62: {
    name: 'XX',
    type: 'normal',
  },
  63: {
    name: 'XO',
    type: 'normal',
  },
  64: {
    name: 'XXX',
    type: 'normal',
  },
  65: {
    name: 'XXO',
    type: 'normal',
  },
};
