import { UUIDs } from 'neo-common-enums';

import { SPORT_GROUP } from '@ntb-sport/constants';

export const getOngoingEvent = (event: any) => {
  const onGoingChildEvent = event?.childEvents?.find(
    (event: any) => event?.eventStatusId === UUIDs.EventStatus.ONGOING,
  );

  const onGoingEvent = event?.eventStatusId === UUIDs.EventStatus.ONGOING;

  return onGoingChildEvent || (onGoingEvent && event);
};

export const getIsNeoSportGroup = (sportGroup: string | string[]) => {
  switch (sportGroup) {
    case SPORT_GROUP.WINTERSPORTS:
    case SPORT_GROUP.ATHLETICS:
    case SPORT_GROUP.CYCLING:
      return true;

    default:
      return false;
  }
};
