import { NifsReferee, NifsStage } from '@ntb-sport/types';
import {
  AttendanceIcon,
  CalendarIcon,
  CompetitionIcon,
  RefereeIcon,
  StadiumFootballIcon,
} from '../../icons';

import {
  COMPONENT_IDS,
  ELEMENT_IDS,
  MATCH_TYPE_NAME,
  REFEREE_TYPE_IDS,
} from '@ntb-sport/constants';

import * as S from './MatchInfo.styled';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import {
  ArrowForwardIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import { useState } from 'react';
import { Button, Skeleton, IconButton, VisuallyHidden } from '../../chakra';

interface MatchInfoProps {
  stage: NifsStage;
  round: number;
  matchTypeId: number;
  timestamp: string;
  stadium: any;
  referees: any;
  attendance: number;
  isInVideoPlayer?: boolean;
  isLoading?: boolean;
  onClickReferees: () => void;
  onClickStadium: () => void;
}

export const MatchInfo = ({
  isInVideoPlayer,
  stage,
  round,
  timestamp,
  matchTypeId,
  stadium,
  referees,
  attendance,
  isLoading,
  onClickReferees,
  onClickStadium,
}: MatchInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const liveName = stage?.names?.find((name: any) => name.useId === 24) || '';

  let name;

  if (stage && stage.stageTypeId === 2) {
    if (MATCH_TYPE_NAME[matchTypeId]) {
      name = `${stage.name}${liveName && ` (${liveName?.name})`}, ${
        MATCH_TYPE_NAME[matchTypeId]
      }`;
    } else {
      name = `${stage.name}${liveName && ` (${liveName?.name})`}${
        round ? `, ${round}. runde.` : ``
      }`;
    }
  } else if (stage && stage.stageTypeId === 1) {
    name = `${stage.name}${liveName && ` (${liveName?.name})`}${
      round ? `, ${round}. runde.` : ``
    }`;
  } else if (stage?.stageTypeId === 3 || stage?.stageTypeId === 4) {
    name = `${stage.name}${liveName && ` (${liveName?.name})`}`;
  } else {
    name = `${round ? `${round}. runde.` : ``}`;
  }

  return (
    <S.Container
      $isInVideoPlayer={isInVideoPlayer}
      data-component-id={COMPONENT_IDS.MATCH_INFO}
      data-tournament-id={stage?.tournament?.id}
    >
      {isLoading ? (
        <Skeleton height="20px" width="100%" />
      ) : (
        <S.HeaderContainerQuery>
          <S.Header
            $isInVideoPlayer={isInVideoPlayer}
            onClick={() => setIsOpen(!isOpen)}
          >
            <S.DefinitionList $isInVideoPlayer={isInVideoPlayer}>
              <S.Definition>
                <S.DefinitionTerm>
                  <VisuallyHidden>Turnering</VisuallyHidden>

                  <CompetitionIcon size="small" aria-hidden="true" />
                </S.DefinitionTerm>
                <S.DefinitionDescription>{name}</S.DefinitionDescription>
              </S.Definition>
            </S.DefinitionList>
            <IconButton
              data-element-id={ELEMENT_IDS.BUTTON}
              size="2xs"
              aria-label={isOpen ? 'Lukk' : 'Se mer'}
              icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              bg="var(--background-color)"
              color="var(--font-color)"
            />
          </S.Header>
        </S.HeaderContainerQuery>
      )}
      {isOpen && (
        <S.AdditionalInfo>
          <S.DefinitionList
            $isInVideoPlayer={isInVideoPlayer}
            data-element-id={ELEMENT_IDS.LIST}
          >
            {timestamp && (
              <S.Definition>
                <S.DefinitionTerm>
                  <VisuallyHidden>Dato</VisuallyHidden>
                  <CalendarIcon size="small" aria-hidden="true" />
                </S.DefinitionTerm>
                <S.DefinitionDescription>
                  {`${format(new Date(timestamp), 'EEEE d. MMMM yyyy', {
                    locale: nb,
                  })} kl. ${format(new Date(timestamp), 'HH:mm', {
                    locale: nb,
                  })}`}
                </S.DefinitionDescription>
              </S.Definition>
            )}
            <MatchInfoStadium
              stadium={stadium}
              onClickStadium={onClickStadium}
            />
            <Referees referees={referees} onClickReferees={onClickReferees} />
            <Attendance attendance={attendance} />
          </S.DefinitionList>
        </S.AdditionalInfo>
      )}
    </S.Container>
  );
};

const Referees = ({
  referees,
  onClickReferees,
}: {
  referees: NifsReferee[];
  onClickReferees: () => void;
}) => {
  if (!referees || referees.length === 0) return null;

  return referees
    ?.filter(
      (referee: NifsReferee) =>
        referee.refereeTypeId === REFEREE_TYPE_IDS.REFEREE,
    )
    ?.map((referee: any) => (
      <S.Definition key={referee.id} data-element-id={ELEMENT_IDS.LIST_ITEM}>
        <S.DefinitionTerm>
          <VisuallyHidden>Dommer</VisuallyHidden>
          <RefereeIcon size="small" aria-hidden="true" />
        </S.DefinitionTerm>
        <S.DefinitionDescription>
          <div>
            {referee.name}
            <Button
              data-element-id={ELEMENT_IDS.BUTTON}
              onClick={onClickReferees}
              size="xs"
              variant={'link'}
              rightIcon={<ArrowForwardIcon />}
              color="var(--font-color)"
            >
              Vis øvrige dommere
            </Button>
          </div>
        </S.DefinitionDescription>
      </S.Definition>
    ));
};

export const MatchInfoStadium = ({
  stadium,
  onClickStadium,
}: {
  stadium: any;
  onClickStadium?: () => void;
}) => {
  if (!stadium) return null;

  const location = stadium.place && stadium.place.name;
  const stadiumName = location ? `${stadium.name}, ${location}` : stadium.name;
  return (
    <S.Definition data-element-id={ELEMENT_IDS.LIST_ITEM}>
      <S.DefinitionTerm>
        <VisuallyHidden>Stadion</VisuallyHidden>
        <StadiumFootballIcon size="small" aria-hidden="true" />
      </S.DefinitionTerm>
      <S.DefinitionDescription>
        <div>
          {stadiumName}
          {onClickStadium && (
            <Button
              data-element-id={ELEMENT_IDS.BUTTON}
              onClick={onClickStadium}
              size="xs"
              variant={'link'}
              rightIcon={<ArrowForwardIcon />}
              color="var(--font-color)"
            >
              Vis stadion
            </Button>
          )}
        </div>
      </S.DefinitionDescription>
    </S.Definition>
  );
};

const Attendance = ({ attendance }: { attendance: any }) => {
  if (attendance === null || attendance === undefined) return null;

  return (
    <S.Definition>
      <S.DefinitionTerm>
        <VisuallyHidden>Tilskuere</VisuallyHidden>
        <AttendanceIcon size="small" aria-hidden="true" />
      </S.DefinitionTerm>
      <S.DefinitionDescription>{attendance}</S.DefinitionDescription>
    </S.Definition>
  );
};
