import { NifsTeamInMatch } from '@ntb-sport/types';
import * as S from './AggregatedWinner.styled';

interface AggregatedWinnerProps {
  matchRelation: any;
  homeTeam: NifsTeamInMatch;
  awayTeam: NifsTeamInMatch;
}

const AggregatedWinner = ({
  matchRelation,
  homeTeam,
  awayTeam,
}: AggregatedWinnerProps) => {
  return (
    matchRelation &&
    Object.prototype.hasOwnProperty.call(
      matchRelation,
      'aggregatedHomeGoals',
    ) && (
      <S.AggregatedWinner>
        {(matchRelation.victoryTypeId === 2 ||
          matchRelation.victoryTypeId === 5) && (
          <div>
            {matchRelation.winningTeamId === homeTeam.id &&
              `${homeTeam.name} videre på bortemål`}
            {matchRelation.winningTeamId === awayTeam.id &&
              `${awayTeam.name} videre på bortemål`}
          </div>
        )}
      </S.AggregatedWinner>
    )
  );
};

export default AggregatedWinner;
