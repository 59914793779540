import MatchTimer from '../../MatchTimer/MatchTimer';
import * as S from './Result.styled';
import { format } from 'date-fns';
import { MATCH_STATUS, SPORT_ID } from '@ntb-sport/constants';

import { Skeleton, Flex } from '../../../chakra';

const getMatchStatus = ({
  sportId,
  matchStatusId,
}: {
  sportId: number;
  matchStatusId: number;
}) => {
  const matchStatus = {
    [MATCH_STATUS.NOT_STARTED]: {
      type: 'default',
      component: 'Kampstart',
    },
    [MATCH_STATUS.ONGOING]: {
      type: 'default',
      component: 'Pågår',
    },
    [MATCH_STATUS.PLAYED]: {
      type: 'default',
      component: 'Slutt',
    },
    [MATCH_STATUS.HALF_TIME]: {
      type: 'default',
      component: sportId === 2 ? 'Første pause' : 'Pause',
    },
    [MATCH_STATUS.SECOND_HALF_TIME]: {
      type: 'default',
      component: sportId === 2 ? 'Andre pause' : 'Pause',
    },
    [MATCH_STATUS.PAUSE_BEFORE_EXTRA_TIME]: {
      type: 'default',
      component: 'Pause før ekstraomganger',
    },
    [MATCH_STATUS.HALF_TIME_EXTRA_TIME]: {
      type: 'default',
      component: 'Pause ekstraomganger',
    },
    [MATCH_STATUS.POSTPONED]: {
      type: 'error',
      component: 'Utsatt',
    },
    [MATCH_STATUS.WILL_NOT_BE_PLAYED]: {
      type: 'error',
      component: 'Avlyst',
    },
    [MATCH_STATUS.CANCELLED]: {
      type: 'error',
      component: 'Strøket',
    },
    [MATCH_STATUS.ABANDONED]: {
      type: 'error',
      component: 'Avbrutt',
    },
    [MATCH_STATUS.PENALTY_SHOOTOUT]: {
      type: 'success',
      component: 'STR',
    },
    [MATCH_STATUS.FIRST_HALF]: {
      type: 'success',
      component:
        sportId === SPORT_ID.ICE_HOCKEY ? (
          <span>Pågår 1. periode</span>
        ) : sportId === SPORT_ID.HANDBALL ? (
          <span>Pågår 1. omgang</span>
        ) : (
          '1. omgang'
        ),
    },
    [MATCH_STATUS.SECOND_HALF]: {
      type: 'success',
      component:
        sportId === SPORT_ID.ICE_HOCKEY ? (
          <span>Pågår 2. periode</span>
        ) : sportId === SPORT_ID.HANDBALL ? (
          <span>Pågår 2. omgang</span>
        ) : (
          '2. omgang'
        ),
    },
    [MATCH_STATUS.THIRD_HALF]: {
      type: 'success',
      component:
        sportId === SPORT_ID.ICE_HOCKEY ? (
          <span>Pågår 3. periode</span>
        ) : sportId === SPORT_ID.HANDBALL ? (
          <span>Pågår 3. omgang</span>
        ) : (
          '3. omgang'
        ),
    },
    [MATCH_STATUS.FIRST_HALF_EXTRA_TIME]: {
      type: 'success',
      component: '1. ekstraomgang',
    },
    [MATCH_STATUS.SECOND_HALF_EXTRA_TIME]: {
      type: 'success',
      component: '2. ekstraomgang',
    },
  };

  return matchStatus[matchStatusId];
};

interface ResultProps {
  sportId: number;
  matchStatusId: number;
  timeStartFirstHalf: string;
  timeStartSecondHalf: string;
  timeStartFirstHalfExtraTime: string;
  timeStartSecondHalfExtraTime: string;
  result: any;
  timestamp: string;
  matchRelation: any;

  isLoading?: boolean;
}

export const Result = ({
  sportId,
  matchStatusId,
  timeStartFirstHalf,
  timeStartSecondHalf,
  timeStartFirstHalfExtraTime,
  timeStartSecondHalfExtraTime,
  result,
  timestamp,
  matchRelation,
  isLoading,
}: ResultProps) => {
  const matchStatus = getMatchStatus({ sportId, matchStatusId });

  const showTimer =
    sportId === SPORT_ID.FOOTBALL &&
    (matchStatusId === MATCH_STATUS.FIRST_HALF ||
      matchStatusId === MATCH_STATUS.SECOND_HALF ||
      matchStatusId === MATCH_STATUS.FIRST_HALF_EXTRA_TIME ||
      matchStatusId === MATCH_STATUS.SECOND_HALF_EXTRA_TIME);

  const timeStart =
    matchStatusId === MATCH_STATUS.FIRST_HALF
      ? timeStartFirstHalf
      : matchStatusId === MATCH_STATUS.SECOND_HALF
        ? timeStartSecondHalf
        : matchStatusId === MATCH_STATUS.FIRST_HALF_EXTRA_TIME
          ? timeStartFirstHalfExtraTime
          : matchStatusId === MATCH_STATUS.SECOND_HALF_EXTRA_TIME
            ? timeStartSecondHalfExtraTime
            : undefined;

  const matchHasExtratime =
    result &&
    sportId === SPORT_ID.FOOTBALL &&
    ((Object.prototype.hasOwnProperty.call(result, 'homeScore105') &&
      result?.homeScore105 !== null) ||
      (Object.prototype.hasOwnProperty.call(result, 'homeScore120') &&
        result?.homeScore120 !== null));

  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <Skeleton width={100} height="30px" />
      </Flex>
    );
  }

  return (
    <S.ResultWrapper>
      <S.AdditionalScores>
        {sportId === SPORT_ID.FOOTBALL &&
          matchRelation?.matchRelationTypeId === 1 && (
            <S.AdditionalScore>Omkamp</S.AdditionalScore>
          )}
        {sportId === SPORT_ID.FOOTBALL &&
          matchRelation?.matchNumber === 1 &&
          matchRelation?.matchRelationTypeId === 2 && (
            <S.AdditionalScore>Kamp 1 av 2</S.AdditionalScore>
          )}
        {sportId === SPORT_ID.FOOTBALL &&
          matchRelation?.matchNumber === 2 &&
          matchRelation?.matchRelationTypeId === 2 && (
            <S.AdditionalScore>Kamp 2 av 2</S.AdditionalScore>
          )}
        {sportId === SPORT_ID.ICE_HOCKEY &&
          matchRelation &&
          Object.prototype.hasOwnProperty.call(matchRelation, 'matchNumber') &&
          Object.prototype.hasOwnProperty.call(
            matchRelation,
            'totalMatches',
          ) && (
            <S.AdditionalScore>
              Kamp {matchRelation?.matchNumber} av {matchRelation?.totalMatches}
            </S.AdditionalScore>
          )}
      </S.AdditionalScores>

      <S.MatchStatus>{matchStatus?.component}</S.MatchStatus>

      <S.Result>
        <S.Score $homeTeam>
          {sportId === SPORT_ID.ICE_HOCKEY
            ? result?.homeScorePenalties ||
              result?.homeScore120 ||
              result?.homeScore90
            : result?.homeScore120 || result?.homeScore90}
        </S.Score>
        <S.DividerWrapper>
          {timestamp && matchStatusId === MATCH_STATUS.NOT_STARTED ? (
            <S.NotStarted>{format(new Date(timestamp), 'HH:mm')}</S.NotStarted>
          ) : showTimer && timeStart ? (
            <MatchTimer
              matchStatusId={matchStatusId}
              timeStart={timeStart}
              showSeconds={true}
            />
          ) : (
            <S.ScoreDivider>-</S.ScoreDivider>
          )}
        </S.DividerWrapper>
        <S.Score $awayTeam>
          {sportId === SPORT_ID.ICE_HOCKEY
            ? result?.awayScorePenalties ||
              result?.awayScore120 ||
              result?.awayScore90
            : result?.awayScore120 || result?.awayScore90}
        </S.Score>
        {matchHasExtratime && matchStatusId === MATCH_STATUS.PLAYED && (
          <S.ExtraTime>etter ekstraomganger</S.ExtraTime>
        )}
        <S.AdditionalScores>
          {(sportId === SPORT_ID.FOOTBALL || sportId === SPORT_ID.HANDBALL) &&
            result &&
            Object.prototype.hasOwnProperty.call(result, 'homeScore120') &&
            result?.homeScore90 !== null && (
              <S.AdditionalScore>{`Ordinær tid: ${result.homeScore90} - ${result.awayScore90}`}</S.AdditionalScore>
            )}
          {sportId === SPORT_ID.FOOTBALL &&
            result &&
            Object.prototype.hasOwnProperty.call(
              result,
              'homeScorePenalties',
            ) &&
            result?.homeScorePenalties !== null && (
              <S.AdditionalScore>{`Straffer: ${result.homeScorePenalties} - ${result.awayScorePenalties}`}</S.AdditionalScore>
            )}
          {sportId === SPORT_ID.ICE_HOCKEY &&
            result &&
            Object.prototype.hasOwnProperty.call(result, 'homeScore120') &&
            !Object.prototype.hasOwnProperty.call(
              result,
              'homeScorePenalties',
            ) && <S.AdditionalScore>{`Etter sudden death`}</S.AdditionalScore>}
          {sportId === SPORT_ID.ICE_HOCKEY &&
            result &&
            Object.prototype.hasOwnProperty.call(
              result,
              'homeScorePenalties',
            ) && <S.AdditionalScore>{`Etter straffer`}</S.AdditionalScore>}
          {sportId === SPORT_ID.ICE_HOCKEY && result && (
            <S.PeriodResults>
              {Object.prototype.hasOwnProperty.call(result, 'scorePeriod1') && (
                <S.AdditionalScore>{`1. periode: ${result.scorePeriod1}`}</S.AdditionalScore>
              )}
              {Object.prototype.hasOwnProperty.call(result, 'scorePeriod2') && (
                <S.AdditionalScore>{`2. periode: ${result.scorePeriod2}`}</S.AdditionalScore>
              )}
              {Object.prototype.hasOwnProperty.call(result, 'scorePeriod3') && (
                <S.AdditionalScore>{`3. periode: ${result.scorePeriod3}`}</S.AdditionalScore>
              )}
            </S.PeriodResults>
          )}

          {matchRelation &&
            Object.prototype.hasOwnProperty.call(
              matchRelation,
              'aggregatedHomeGoals',
            ) &&
            matchRelation?.matchNumber === 2 && (
              <S.CombinedResult>
                {`Sammenlagt: ${
                  matchRelation.aggregatedHomeGoalsExtraTime ||
                  matchRelation.aggregatedHomeGoals
                } - ${
                  matchRelation.aggregatedAwayGoalsExtraTime ||
                  matchRelation.aggregatedAwayGoals
                }`}
              </S.CombinedResult>
            )}
          {sportId !== SPORT_ID.FOOTBALL &&
            matchRelation &&
            Object.prototype.hasOwnProperty.call(matchRelation, 'score') && (
              <S.CombinedResult>{`Sammenlagt: ${matchRelation.score}`}</S.CombinedResult>
            )}
        </S.AdditionalScores>
      </S.Result>
    </S.ResultWrapper>
  );
};

export default Result;
