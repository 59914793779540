import { COMPONENT_IDS, MATCH_STATUS } from '@ntb-sport/constants';

import * as S from './TeamFixturesTeam.styled';
import { Skeleton, SkeletonCircle } from '../../chakra';

export interface TeamFixturesTeamProps {
  team: any;
  highlightWinningTeam?: boolean;
  isLoading?: boolean;
  isHomeTeam?: boolean;
  winningTeamId?: number | null;
  matchRelation?: any;
  matchStatusId: number;
  onClickTeam?: any;
  sportId: number;
  teamId?: number;
}

export const TeamFixturesTeam: React.FC<TeamFixturesTeamProps> = ({
  isLoading,
  isHomeTeam,
  winningTeamId,
  onClickTeam,
  highlightWinningTeam,
  matchStatusId,
  team,
  matchRelation,
  sportId,
}) => {
  return (
    <S.Team
      data-component-id={COMPONENT_IDS.TEAM_FIXTURES_TEAM}
      $isHomeTeam={isHomeTeam}
      $highlightWinningTeam={
        matchStatusId === MATCH_STATUS.PLAYED &&
        highlightWinningTeam &&
        Boolean(winningTeamId) &&
        matchRelation?.matchNumber === 2
      }
      $isWinningTeam={winningTeamId === team?.id}
      $isClickable={onClickTeam}
      onClick={() =>
        onClickTeam &&
        onClickTeam({
          name: team.name,
          id: team.id,
          sportId,
        })
      }
    >
      {isLoading ? (
        <>
          <SkeletonCircle
            width={35}
            height={35}
            style={{
              marginRight: '10px',
            }}
          />{' '}
          <Skeleton width={120} />
        </>
      ) : (
        <>
          <S.TeamLogoWrapper $isHomeTeam={true}>
            {team && team.logo && (
              <S.TeamLogo aria-hidden src={team.logo.url} alt={team.name} />
            )}
          </S.TeamLogoWrapper>

          <S.TeamName
            $highlightWinningTeam={
              matchStatusId === MATCH_STATUS.PLAYED &&
              highlightWinningTeam &&
              Boolean(winningTeamId) &&
              matchRelation?.matchNumber === 2
            }
            $isWinningTeam={winningTeamId === team?.id}
            $isClickable={onClickTeam}
          >
            {team?.name}
          </S.TeamName>
        </>
      )}
    </S.Team>
  );
};
