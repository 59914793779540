import { ArrowIcon, CircleIcon } from '../../icons';
import styled, { css } from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $textAlign?: string;
  $isSorted?: boolean;
  $divider?: boolean;
  $collapse?: string;
  $displayDividerTop?: boolean;
  $displayDividerBottom?: boolean;
  $highlight?: boolean;
  $justifyContent?: string;
  $rowTypeId?: number;
  $result?: any;
  $highlightedTeam?: any;
}

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Row = styled.tr<StyledProps>`
  background-color: ${(props: any) =>
    props?.$highlightedTeam
      ? 'var(--background-color__active)'
      : 'var(--background-color)'};

  color: ${(props: any) =>
    props?.$highlightedTeam
      ? 'var(--font-color__active)'
      : 'var(--font-color)'};

  /* box-shadow: inset 0px -1px 0px var(--border-color); */

  ${(props: any) =>
    props?.$displayDividerBottom
      ? css`
          box-shadow: inset 0px -2px 0px var(--border-color);
        `
      : props?.$displayDividerTop
        ? css`
            box-shadow: inset 0px 2px 0px var(--border-color);
            /* inset 0px -1px 0px var(--border-color); */
          `
        : css`
            /* box-shadow: inset 0px -1px 0px var(--border-color); */
          `}

  /* &:first-child {
    box-shadow: none;
  } */

  &:last-child {
    box-shadow: none;
  }

  &:hover {
    background-color: var(--background-color__hover);
    color: var(--font-color__hover);
  }
`;

export const DividerRow = styled.tr`
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
`;

export const DividerCell = styled.td<StyledProps>`
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
    padding: var(--ntb-space-2);
  }

  @container container ${MEDIA_QUERY.DESKTOP} {
    font-size: var(--ntb-fontSizes-md);
  }
`;

export const HeaderCell = styled.th<StyledProps>`
  text-align: ${(props) => props.$textAlign || 'left'};
  padding: var(--ntb-space-1) var(--ntb-space-2);
  /* border-left: ${(props) =>
    props.$divider && `1px solid var(--ntb-column-border-color)`}; */

  background-color: ${(props) =>
    props.$isSorted && 'var(--base-colors-blackAlpha-50)'};
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  @container container ${MEDIA_QUERY.TABLET} {
    padding: var(--ntb-space-1) var(--ntb-space-2);
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-sm);
    padding: var(--ntb-space-2) var(--ntb-space-3);
  }

  ${(props) =>
    props.$collapse === 'all' &&
    css`
      width: 1%;
      white-space: nowrap;
    `};

  ${(props) =>
    props.$collapse === 'left' &&
    css`
      width: 1%;
      white-space: nowrap;
      padding-left: 0;
    `};
`;

export const Cell = styled.td<StyledProps>`
  text-align: ${(props) => props.$textAlign || 'left'};
  padding: var(--ntb-space-1) var(--ntb-space-2);
  border-left-width: ${(props) => props.$divider && `1px`};
  border-left-style: ${(props) => props.$divider && `solid`};
  width: ${(props) => props.width || 'auto'};
  background-color: ${(props) => props.$isSorted && 'rgba(0,0,0,0.05)'};

  font-size: var(--ntb-fontSizes-xs);

  border-left-color: ${(props: any) =>
    props?.$highlightedTeam && props?.divider
      ? 'var(--highlighted-team-border-color, transparent)'
      : props?.divider
        ? 'var(--column-border-color, transparent)'
        : 'transparent'};

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-xs);
  }

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
    padding: var(--ntb-space-1) var(--ntb-space-2);
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    padding: var(--ntb-space-2) var(--ntb-space-3);
    font-size: var(--ntb-fontSizes-md);
  }

  ${(props) =>
    props.$collapse === 'all' &&
    css`
      width: 1%;
      white-space: nowrap;
    `};

  ${(props) =>
    props.$collapse === 'left' &&
    css`
      width: 1%;
      white-space: nowrap;
      padding-left: 0;
    `};

  font-weight: ${(props) =>
    props.$highlight
      ? 'var(--ntb-fontWeights-semibold)'
      : 'var(--ntb-fontWeights-normal)'};

  &:first-child {
    border-left: none;
  }
`;

export const CellButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.$justifyContent || 'center'};
`;

export const Placement = styled.div<StyledProps>`
  padding: var(--ntb-space-1) 0 var(--ntb-space-1) var(--ntb-space-2);
  border-left-style: solid;
  border-left-width: 3px;
  border-left-color: transparent;

  @container container ${MEDIA_QUERY.TABLET} {
    border-left-width: 4px;
  }

  ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 1 &&
    css`
      border-left-color: var(--base-colors-success);
    `}

  ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 2 &&
    css`
      border-left-color: var(--base-colors-error);
    `}

    ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 3 &&
    css`
      border-left-color: var(--base-colors-warning);
    `}

    ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 4 &&
    css`
      border-left-color: var(--base-colors-highlight);
    `}

  ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 5 &&
    css`
      border-left-color: var(--base-colors-success);
    `}

    ${(props) =>
    props?.$rowTypeId &&
    props?.$rowTypeId === 6 &&
    css`
      border-left-color: var(--base-colors-warning);
    `}
`;

export const Team = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const TeamLogo = styled.img<StyledProps>`
  max-width: 20px;
  max-height: 20px;

  @container container ${MEDIA_QUERY.TABLET} {
    max-width: 28px;
    max-height: 28px;
  }
`;

export const TeamName = styled.div`
  font-weight: var(--ntb-fontWeights-semibold);

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SortArrows = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: var(--ntb-space-1);
`;

export const SortArrowUp = styled(ArrowIcon)`
  fill: var(--font-color);
  width: 8px;
  height: 8px;
  transform: rotate(-180deg);
`;

export const SortArrowDown = styled(ArrowIcon)`
  fill: var(--font-color);
  width: 8px;
  height: 8px;
  transform: rotate(0deg);
`;

export const FormIcon = styled(CircleIcon)<StyledProps>`
  display: inline;
  fill: ${(props) =>
    props.$result === 1
      ? 'var(--base-colors-success)'
      : props.$result === 2
        ? 'var(--base-colors-error)'
        : 'var(--base-colors-warning)'};
`;
