import { MatchesType, NifsMatch } from '@ntb-sport/types';
import NrkVideoPlayer from '../../common/MediaPlayer/NrkVideoPlayer';
import { forwardRef } from 'react';
import * as S from './VideoHero.styled';
import { MatchInfo } from '../MatchInfo/MatchInfo';
import { COMPONENT_IDS } from '@ntb-sport/constants';

interface VideoHeroProps {
  match: NifsMatch;
  matches: MatchesType;
  customerConcernId?: number;
  onClickReferees: () => void;
  onClickStadium: () => void;
}

export const VideoHero = forwardRef<VideoHeroProps, any>(
  (
    { match, matches, customerConcernId, onClickReferees, onClickStadium },
    ref: any,
  ) => {
    const matchStreams = match?.matchStreams;
    const videoStream = matchStreams?.find(
      (matchStream: any) =>
        !matchStream?.radio &&
        (matchStream?.customer?.customerConcernId === customerConcernId ||
          matchStream?.customerConcernId === customerConcernId),
    );

    if (!videoStream) return null;

    return (
      <S.Container
        ref={ref}
        $matches={matches}
        data-component-id={COMPONENT_IDS.VIDEO_HERO}
        data-tournament-id={match?.stage.tournament.id}
      >
        <MatchInfo
          isInVideoPlayer={true}
          stage={match?.stage}
          round={match?.round}
          matchTypeId={match?.matchTypeId}
          timestamp={match?.timestamp}
          stadium={match?.stadium}
          referees={match?.referees}
          attendance={match?.attendance}
          onClickReferees={onClickReferees}
          onClickStadium={onClickStadium}
        />
        <S.PlayerContainer>
          <NrkVideoPlayer videoId={videoStream?.link} />
        </S.PlayerContainer>
      </S.Container>
    );
  },
);
