import { useBem } from '@ntb-sport/hooks';
import './check-points.css';
import '../active-athlete.css';
import classNames from 'classnames';
import {
  SporteditActiveAthleteParticipant,
  SportEditActiveAthleteScopeResult,
} from '@ntb-sport/types';

export const Checkpoints = ({
  eventParticipant,
  scopeResults,
  isScopeResultsAboveThree,
}: {
  eventParticipant: SporteditActiveAthleteParticipant;
  scopeResults: SportEditActiveAthleteScopeResult[];
  isScopeResultsAboveThree?: boolean;
}) => {
  const bem = useBem('check-points');

  const getColorScheme = (timeDifference: string) => {
    if (Math.sign(Number(timeDifference)) === 1) return 'red';
    if (Math.sign(Number(timeDifference)) === -1 || timeDifference === '+0.00')
      return 'green';
    return '';
  };

  const isExtendedScopeResults = isScopeResultsAboveThree ? 'extended' : '';

  return (
    eventParticipant && (
      <div className={classNames(bem('', isExtendedScopeResults))}>
        <div className={bem('titles')}>
          <p>Mellomtid</p>
          <p>Differanse</p>
          <p>Plassering</p>
        </div>

        {scopeResults &&
          scopeResults.map((scopeResult, index: number) => {
            const colorScheme = getColorScheme(
              scopeResult.timeDifferenceToLeaderLive ??
                (scopeResult.timeDifference as string),
            );

            return (
              <div
                key={scopeResult.uuid}
                className={classNames(bem('result', isExtendedScopeResults))}
              >
                <div className={bem('scopes')}>{index + 1}</div>
                <div className={classNames(bem('tag', colorScheme))}>
                  {scopeResult.timeDifferenceToLeaderLive || (
                    <div className="active-athlete-spinner">
                      <span />
                    </div>
                  )}
                </div>
                <div className={bem('tag')}>
                  {scopeResult.rank || (
                    <div className="active-athlete-spinner">
                      <span />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    )
  );
};
