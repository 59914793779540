import { Container as ChakraContainer } from '@chakra-ui/react';

export const Container = (props: any) => {
  const { innerRef, ...rest } = props;
  return (
    <ChakraContainer ref={innerRef} {...rest}>
      {props.children}
    </ChakraContainer>
  );
};

export default Container;
