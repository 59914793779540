export const REFEREE_TYPE_IDS = {
  REFEREE: 1,
  ASSISTANT_REFEREES: 2,
  FOURTH_REFEREE: 4,
  GOAL_LINE_ASSISTANT_REFEREE: 5,
  VAR_REFEREE: 6,
  VAR_OPERATOR: 7,
  VAR_SUPERVISOR: 8,
  REFEREE_SUPERVISOR: 9,
  MATCH_DELEGATE: 10,
  MENTOR: 11,
  SECURITY_DELEGATE: 12,
  ASSISTANT_REFEREE: 13,
  ASSISTANT_VAR_REFEREE: 14,
  MENTOR_2: 16,
};

export const REFEREE_TYPE_ID_TO_NORWEGIAN_NAME = {
  1: 'Hoveddommer',
  2: 'Assistentdommere',
  4: 'Fjerdedommer',
  5: 'Mållinjedommer',
  6: 'VAR-dommere',
  7: 'Repriseoperatør',
  8: 'VAR-supervisor',
  9: 'Dommerveileder',
  10: 'Kampdelegat',
  11: 'Mentor',
  12: 'Sikkerhetsdelegat',
  13: 'Assistentdommer',
  14: 'Assisterende VAR-dommer',
  16: 'Mentor 2',
};

export const VISIBLE_REFEREE_GROUPS = {
  [REFEREE_TYPE_IDS.REFEREE]: {
    name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[
      REFEREE_TYPE_IDS.REFEREE as keyof typeof REFEREE_TYPE_ID_TO_NORWEGIAN_NAME
    ],
    referees: [],
  },
  [REFEREE_TYPE_IDS.ASSISTANT_REFEREES]: {
    name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[
      REFEREE_TYPE_IDS.ASSISTANT_REFEREES as keyof typeof REFEREE_TYPE_ID_TO_NORWEGIAN_NAME
    ],
    referees: [],
  },
  [REFEREE_TYPE_IDS.FOURTH_REFEREE]: {
    name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[
      REFEREE_TYPE_IDS.FOURTH_REFEREE as keyof typeof REFEREE_TYPE_ID_TO_NORWEGIAN_NAME
    ],
    referees: [],
  },
  [REFEREE_TYPE_IDS.VAR_REFEREE]: {
    name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[
      REFEREE_TYPE_IDS.VAR_REFEREE as keyof typeof REFEREE_TYPE_ID_TO_NORWEGIAN_NAME
    ],
    referees: [],
  },
};
